import * as React from 'react';
import Typography from '@mui/material/Typography';
import SignaturePad from 'react-signature-pad-wrapper';
import Grid from '@mui/material/Grid';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import FormGroup from '@mui/material/FormGroup';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Link from '@mui/material/Link';
import CircularProgress from '@mui/material/CircularProgress';

export default function Sign(props) {
  const { t, i18n } = useTranslation();
  const signaturePadRef = React.createRef();

  const { contract, contractText } = props;
  const { setContract } = props;

  const { postCheckin, handleBack } = props;
  const { signature, setSignature } = props;

  const [openContract, setOpenContract] = React.useState(false);

  const [errorSignature, setErrorSignature] = React.useState(false);
  const [errorContract, setErrorContract] = React.useState(false);
  const [isSending, setIsSending] = React.useState(false);

  const handleCloseContract = () => {
    setOpenContract(false);
  };

  const next = (e) => {
    setIsSending(true);
    const signaturePad = signaturePadRef.current;
    e.preventDefault();
    let error = false;

    setErrorSignature(false);
    setErrorContract(false);

    if (!signaturePad || signaturePad.isEmpty()) {
      setErrorSignature(true);
      error = true;
    }

    if (!contract) {
      setErrorContract(true);
      error = true;
    }

    if (error) {
      return;
    }

    setSignature(signaturePad.toDataURL());
  };

  const back = (e) => {
    e.preventDefault();
    handleBack();
  };

  React.useEffect(async () => {
    if (signature) {
      await postCheckin();
      setIsSending(false);
    }
  }, [signature]);

  return (
    <React.Fragment>
      <Dialog
        open={openContract}
        onClose={handleCloseContract}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>{t('Contract')}</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            {contractText.hasOwnProperty(i18n.language)
              ? contractText[i18n.language]
              : contractText['en']}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseContract}>{t('Close')}</Button>
        </DialogActions>
      </Dialog>

      <Typography variant='h6' gutterBottom align="center">
        {t('Signature')}
      </Typography>
      <Grid container>
        <Grid container direction={'row'}>
          <Grid
            item
            xs={12}
            sx={{
              boxShadow: 1,
              ml: 'auto',
              mr: 'auto',
              mt: 2,
              mb: 2,
              border: 1,
              borderColor: 'black'
            }}
          >
            <SignaturePad redrawOnResize ref={signaturePadRef} />
          </Grid>
          <Grid item xs={12} sm={12} style={{ margin: 'auto' }}>
            {errorSignature && (
              <FormHelperText error>
                {t('The signature is mandatory')}
              </FormHelperText>
            )}
          </Grid>

          <Grid item xs={4} sm={3} style={{ margin: 'auto', textAlign: 'center' }}>
            <Button
              variant='contained'
              style={{ backgroundColor: '#ffbd21', margin: 'auto' }}
              onClick={() => {
                const signaturePad = signaturePadRef.current;
                if (signaturePad) {
                  signaturePad.instance.clear();
                }
              }}
            >
              {t('Clear')}
            </Button>
          </Grid>

          <Grid container sx={{ pt: 1 }} alignItems='center'>
            <Grid item xs={8} sm={7} style={{ margin: 'auto' }}>
              <FormGroup>
                <FormControl error={errorContract}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={contract}
                        onChange={(event) => {
                          setContract(event.target.checked);
                        }}
                      />
                    }
                    label={
                      <Typography noWrap color='textPrimary'>
                        {t('I have read and accept the ')}
                        <Link
                          href='#'
                          onClick={() => setOpenContract(true)}
                          style={{
                            color: 'black',
                            textDecorationColor: 'black'
                          }}
                        >
                          {t('contract')}
                        </Link>
                      </Typography>
                    }
                  />
                  {errorContract && (
                    <FormHelperText>
                      {t('The contract must be accepted')}
                    </FormHelperText>
                  )}
                </FormControl>
              </FormGroup>
            </Grid>
          </Grid>

          <Grid container spacing={0} direction='row' sx={{ mt: 3 }}>
            <Grid item xs={6} sm={6} style={{ margin: 'auto' }}>
              <React.Fragment>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Button onClick={back}>{t('Back')}</Button>
                  <Button variant='contained' onClick={next} color='primary' disabled={isSending}>
                    {isSending ? 
                      <CircularProgress />
                      :
                      t('Send')
                    }
                  </Button>
                </Box>
              </React.Fragment>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
