import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import countries from './CountryList';

const LANGUAGES = ['de', 'es', 'en', 'fr', 'it', 'ja', 'nl', 'pt', 'ru', 'sv', 'zh'];

export default function ProvinceSelect(props) {
  const { province } = props;
  const { setProvince } = props;
  const { t } = useTranslation();

  const activeLanguage = window.localStorage.getItem('gatsby-i18next-language');
  let languageContries = 'en';
  if (LANGUAGES.includes(activeLanguage)) {
    languageContries = activeLanguage;
  } else {
    if (activeLanguage === 'ca') {
      languageContries = 'es';
    }
  }

  const provincias = [
    'alava',
    'albacete',
    'alicante',
    'almeria',
    'asturias',
    'avila',
    'badajoz',
    'barcelona',
    'burgos',
    'caceres',
    'cadiz',
    'cantabria',
    'castellon',
    'ciudad real',
    'cordoba',
    'cuenca',
    'gerona',
    'granada',
    'guadalajara',
    'guipuzcoa',
    'huelva',
    'huesca',
    'islas baleares',
    'jaen',
    'la coruna',
    'la rioja',
    'las palmas',
    'leon',
    'lerida',
    'lugo',
    'madrid',
    'malaga',
    'murcia',
    'navarra',
    'orense',
    'palencia',
    'pontevedra',
    'salamanca',
    'santa cruz de tenerife',
    'segovia',
    'sevilla',
    'soria',
    'tarragona',
    'teruel',
    'toledo',
    'valencia',
    'valladolid',
    'vizcaya',
    'zamora',
    'zaragoza',
    'ceuta',
    'melilla'
  ];

  return (
    <Autocomplete
      id='country-select'
      value={province}
      options={provincias}
      autoHighlight
      onChange={(event, value) => {
        setProvince(value);
      }}
      renderOption={(props, option) => (
        <Box
          component='li'
          sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
          {...props}
        >
          {option.toUpperCase()}
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label={t('Province')}
          inputProps={{
            ...params.inputProps
          }}
        />
      )}
    />
  );
}
