import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import Paper from '@mui/material/Paper';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import Start from './Start';
import PersonalData from './PersonalData';
import DocumentData from './DocumentData';
import Sign from './Sign';
import Payment from './Payment';
import { useTranslation, useI18next } from 'gatsby-plugin-react-i18next';
import MenuItem from '@mui/material/MenuItem';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import axios from 'axios';
import { useQueryParam, StringParam } from 'use-query-params';
import DotLoader from 'react-spinners/DotLoader';
import countries from './CountryList';

function Copyright() {
  return (
    <Typography variant='body2' color='text.secondary' align='center'>
      {'Copyright © '}
      <Link color='inherit' target={'_blank'} href='https://journeytok.com/'>
        Journeytok
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}
const useStyles = makeStyles({
  '@global': {
    '& .Mui-focused .MuiOutlinedInput-notchedOutline .select-idioma': {
      border: 0
    },
    '.MuiMenu-list': {
      paddingLeft: '0',
      paddingTop: '0',
      paddingBottom: '0'
    },
    '.MuiMenu-list :hover': {
      backgroundColor: '#D3D3D3 !important'
    },
    '.MuiTextField-root': {
      width: '100%'
    }
  }
});

export default function Checkin() {
  const [activeStep, setActiveStep] = React.useState(0);
  const { i18n, t } = useTranslation();
  const [language, setLanguage] = React.useState(i18n.language ?? 'es');
  const { changeLanguage } = useI18next();

  const [hotelName, setHotelName] = React.useState('');
  const [bookingID, setBookingID] = React.useState('');
  const [departure, setDeparture] = React.useState(null);
  const [adults, setAdults] = React.useState(0);
  const [children, setChildren] = React.useState(0);

  const [email, setEmail] = React.useState('');
  const [phone, setPhone] = React.useState('');
  const [postalCode, setPostalCode] = React.useState('');
  const [arrivalDate, setArrivalDate] = React.useState(
    moment().set('hour', 12).set('minutes', 0).toDate()
  );
  const [name, setName] = React.useState('');
  const [lastName, setLastName] = React.useState('');
  const [lastName2, setLastName2] = React.useState('');
  const [birthDate, setBirthDate] = React.useState(null);
  const [sex, setSex] = React.useState('');
  const [country, setCountry] = React.useState(null);
  const [documentType, setDocumentType] = React.useState('');
  const [document, setDocument] = React.useState('');
  const [province, setProvince] = React.useState('');
  const [city, setCity] = React.useState('');
  const [address, setAddress] = React.useState('');
  const [issueDate, setIssueDate] = React.useState(null);
  const [expiryDate, setExpiryDate] = React.useState(null);

  const [privacyPolicy, setPrivacyPolicy] = React.useState(false);
  const [personalisedOffers, setPersonalisedOffers] = React.useState(false);

  const [signature, setSignature] = React.useState('');
  const [contract, setContract] = React.useState(false);
  const [contractText, setContractText] = React.useState('');

  const [backgroundColor, setBackgroundColor] = React.useState('#ffbd21');
  const [logo, setLogo] = React.useState(null);

  const [idCheckin] = useQueryParam('idCheckin', StringParam);
  const [request] = useQueryParam('request', StringParam);
  const [pago, setPago] = React.useState(false);
  const [urlPago, setUrlPago] = React.useState('');
  const [ocrActivo, setOcrActivo] = React.useState(false);
  const [privacyPolicyText, setPrivacyPolicyText] = React.useState(false);
  const [personalisedOffersText, setPersonalisedOffersText] =
    React.useState(false);

  const [esTitular, setEsTitular] = React.useState(true);
  const [huespedes, setHuespedes] = React.useState([]);
  const [titularRellenando, setTitularRellenando] = React.useState(false);
  const [datosObligatorios, setDatosObligatorios] = React.useState({
    email: true,
    phone: true,
    postalCode: true,
    arrivalDate: true
  });
  const [datosSoloLectura, setDatosSoloLectura] = React.useState({
    firstName: false,
    lastName: false,
    documento: false
  });
  const [loading, setLoading] = React.useState(true);
  const [mostrarError, setMostrarError] = React.useState(false);
  const [tipoError, setTipoError] = React.useState('');
  const [tokenOcr, setTokenOcr] = React.useState('');

  const [errorCode, setErrorCode] = React.useState(null);
  const [enviando, setEnviando] = React.useState(false);
  const [idUsuarios, setIdUsuarios] = React.useState(0);
  const [nombreHabitacion, setNombreHabitacion] = React.useState('');

  const DATE_FORMAT = 'YYYY-MM-DD HH:mm:ss';
  useStyles();

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <Start
            hotelName={hotelName}
            bookingID={bookingID}
            arrivalDate={arrivalDate}
            departure={departure}
            adults={adults}
            children={children}
            huespedes={huespedes}
            handleNext={handleNext}
            setEsTitular={setEsTitular}
            inicio={inicio}
            setIdUsuarios={setIdUsuarios}
            setNombreHabitacion={setNombreHabitacion}
          />
        );
      case 1:
        return (
          <PersonalData
            esTitular={esTitular}
            email={email}
            phone={phone}
            postalCode={postalCode}
            arrivalDate={arrivalDate}
            datosObligatorios={datosObligatorios}
            nombreHabitacion={nombreHabitacion}
            setEmail={setEmail}
            setPhone={setPhone}
            setPostalCode={setPostalCode}
            setArrivalDate={setArrivalDate}
            privacyPolicy={privacyPolicy}
            setPrivacyPolicy={setPrivacyPolicy}
            personalisedOffers={personalisedOffers}
            privacyPolicyText={privacyPolicyText}
            personalisedOffersText={personalisedOffersText}
            setPersonalisedOffers={setPersonalisedOffers}
            handleNext={handleNext}
            handleBack={handleBack}
            errorCode={errorCode}
          />
        );
      case 2:
        return (
          <DocumentData
            bookingID={bookingID}
            name={name}
            lastName={lastName}
            lastName2={lastName2}
            birthDate={birthDate}
            sex={sex}
            country={country}
            documentType={documentType}
            document={document}
            province={province}
            city={city}
            address={address}
            issueDate={issueDate}
            expiryDate={expiryDate}
            datosSoloLectura={datosSoloLectura}
            setName={setName}
            setLastName={setLastName}
            setLastName2={setLastName2}
            setBirthDate={setBirthDate}
            setSex={setSex}
            setCountry={setCountry}
            setDocumentType={setDocumentType}
            setDocument={setDocument}
            setProvince={setProvince}
            setCity={setCity}
            setAddress={setAddress}
            setIssueDate={setIssueDate}
            setExpiryDate={setExpiryDate}
            handleNext={handleNext}
            handleBack={handleBack}
            titularRellenando={titularRellenando}
            errorCode={errorCode}
            ocrActivo={ocrActivo}
            tokenOcr={tokenOcr}
            idCheckin={idCheckin}
            request={request}
            esTitular={esTitular}
            postalCode={postalCode}
            setPostalCode={setPostalCode}
          />
        );
      case 3:
        return (
          <Sign
            contract={contract}
            signature={signature}
            setContract={setContract}
            setSignature={setSignature}
            postCheckin={postCheckin}
            contractText={contractText}
            handleBack={handleBack}
          />
        );
      case 4:
        return (
          <Payment
            handleBack={handleBack}
            hotelName={hotelName}
            bookingID={bookingID}
            arrivalDate={arrivalDate}
            departure={departure}
            urlPago={urlPago}
            handleNext={handleNext}
          />
        );
      default:
        throw new Error('Unknown step');
    }
  };

  React.useEffect(() => {
    changeLanguage(language);
  }, [language]);

  const theme = createTheme();

  const handleChangeLanguage = (event) => {
    setLanguage(event);
  };

  const handleNext = () => {
    if (activeStep === 3) {
      if (!pago || urlPago === '' || !esTitular) {
        setActiveStep(0);
      } else {
        setActiveStep(activeStep + 1);
      }
    } else if (activeStep === 2) {
      if (titularRellenando) {
        postCheckin();
      } else {
        setActiveStep(activeStep + 1);
      }
    } else {
      setActiveStep(activeStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const inicio = () => {
    setEsTitular(false);
    setTitularRellenando(true);
    setEmail('');
    setPhone('');
    setName('');
    setLastName('');
    setLastName2('');
    setSex('');
    setPago(false);
    setUrlPago('');
    setPostalCode('');
    setActiveStep(2);
    setSignature('');
    setBirthDate(null);
    setCountry(null);
    setDocumentType('');
    setDocument('');
    setProvince('');
    setAddress('');
    setCity('');
    setIssueDate(null);
    setExpiryDate(null);
    setPrivacyPolicy(false);
    setPersonalisedOffers(false);
  };

  const getCheckin = async () => {
    try {
      if (!idCheckin) {
        return;
      }
      const result = await axios.get(
        `/apiCheckin/checkin?idCheckin=${idCheckin}&request=${request}`
      );
      const jsonData = result.data;
      const { error, mensaje_error } = result.data;
      if (error) {
        if (mensaje_error) {
          throw mensaje_error;
        } else {
          throw new Error('ERROR');
        }
      }
      setHotelName(jsonData.hotelName);
      setBookingID(jsonData.bookingID);
      setArrivalDate(moment(jsonData.arrival, DATE_FORMAT).toDate());
      setDeparture(moment(jsonData.departure, DATE_FORMAT).toDate());
      setAdults(jsonData.adults);
      setChildren(jsonData.children);
      if (esTitular) {
        if (jsonData.email) {
          setEmail(jsonData.email);
        }
        if (jsonData.phone) {
          setPhone(jsonData.phone?.replace(/\s/g, ''));
        }
        if (jsonData.name) {
          setName(jsonData.name);
        }
        if (jsonData.lastName) {
          setLastName(jsonData.lastName);
        }
        if (jsonData.lastName2) {
          setLastName2(jsonData.lastName2);
        }
        if (jsonData.sex) {
          setSex(jsonData.sex);
        }
        if (jsonData.country) {
          setCountry(countries.filter(
            (country) => country.alpha2 === jsonData.country.toLowerCase()
          )[0]);
        }
        if (jsonData.province) {
          setProvince(jsonData.province);
        }
        if (jsonData.city) {
          setCity(jsonData.city);
        }
        if (jsonData.address) {
          setAddress(jsonData.address);
        }
        if (jsonData.birthday) {
          setBirthDate(jsonData.birthday);
        }
        if (jsonData.issueDate) {
          setIssueDate(jsonData.issueDate);
        }
        if (jsonData.expiryDate) {
          setExpiryDate(jsonData.expiryDate);
        }
        if (jsonData.documento) {
          setDocument(jsonData.documento);
        }
        if (jsonData.tipoDocumento) {
          setDocumentType(jsonData.tipoDocumento);
        }
        if (jsonData.pago) {
          setPago(jsonData.pago);
        }
        if (jsonData.urlPago) {
          setUrlPago(jsonData.urlPago);
        }
      }
      if (jsonData.backgroundColor) {
        setBackgroundColor(jsonData.backgroundColor);
      }
      if (jsonData.logo) {
        setLogo(jsonData.logo);
      }
      if (jsonData.contractText) {
        setContractText(JSON.parse(jsonData.contractText));
      }
      const auxDatosObligatorios = structuredClone(datosObligatorios);
      if (jsonData?.datosObligatorios?.email === false) {
        auxDatosObligatorios.email = false;
      }
      if (jsonData?.datosObligatorios?.phone === false) {
        auxDatosObligatorios.phone = false;
      }
      if (jsonData?.datosObligatorios?.postalCode === false) {
        auxDatosObligatorios.postalCode = false;
      }
      if (jsonData?.datosObligatorios?.arrivalDate === false) {
        auxDatosObligatorios.arrivalDate = false;
      }
      const auxDatosDatosSoloLectura = structuredClone(datosSoloLectura);
      if (jsonData?.datosFijos?.firstName) {
        auxDatosDatosSoloLectura.firstName = true;
      } else {        
        auxDatosDatosSoloLectura.firstName = false;
      }
      if (jsonData?.datosFijos?.lastName) {
        auxDatosDatosSoloLectura.lastName = true;
      } else {        
        auxDatosDatosSoloLectura.lastName = false;
      }
      if (jsonData?.datosFijos?.documento) {
        auxDatosDatosSoloLectura.documento = true;
      } else {        
        auxDatosDatosSoloLectura.documento = false;
      }
      setDatosSoloLectura(auxDatosDatosSoloLectura);
      if (jsonData.ocr && esTitular) {
        if (jsonData.token && jsonData.token !== '') {
          setTokenOcr(jsonData.token);
          setOcrActivo(true);
        }
      }
      if (jsonData.politicaPrivacidad) {
        setPrivacyPolicyText(JSON.parse(jsonData.politicaPrivacidad));
      }
      if (jsonData.comunicadosComerciales) {
        setPersonalisedOffersText(JSON.parse(jsonData.comunicadosComerciales));
      }
      setDatosObligatorios(auxDatosObligatorios);
      if (jsonData.datosHuespedes) {
        setHuespedes(jsonData.datosHuespedes);
      }
      setLoading(false);
    } catch (error) {
      setMostrarError(true);
      if (error === 'CHECKIN_ERROR_PASADO') {
        setTipoError('CHECKIN_ERROR_PASADO');
      } else {
        setTipoError(error);
      }
      // quitar antes de subir a producción
      console.log(error);
    }
  };

  const getTokenNoTitular = async () => {
    try {
      if (!idCheckin) {
        return;
      }
      const result = await axios.get(
        `/apiCheckin/getTokenOcr?idCheckin=${idCheckin}&request=${request}`
      );
      const jsonData = result.data;
      const { error, mensaje_error } = result.data;
      if (error) {
        if (mensaje_error) {
          throw Error(mensaje_error);
        } else {
          throw Error('ERROR');
        }
      }
      if (!esTitular && jsonData.token && jsonData.token !== '') {
        setTokenOcr(jsonData.token);
        setOcrActivo(true);
      }
    } catch (error) {
      // quitar antes de subir a producción
      console.log(error);
    }
  };

  React.useEffect(() => {
    getCheckin();
  }, []);

  const postCheckin = async () => {
    if (enviando) {
      return;
    }
    setEnviando(true);
    const body = {
      idCheckin,
      request,
      bookingID,
      email,
      phone,
      postalCode,
      arrivalDate,
      name,
      lastName,
      lastName2,
      birthDate,
      sex,
      country,
      documentType,
      document,
      province,
      city,
      address,
      issueDate,
      expiryDate,
      privacyPolicy,
      personalisedOffers,
      contract,
      signature,
      esTitular,
      titularRellenando,
      idUsuarios
    };
    try {
      const result = await axios.post('/apiCheckin/serviceCheckin', body);
      const { data } = result;
      const { datosHuespedes } = data;
      const { error } = result.data;
      if (error) {
        throw Error('ERROR');
      }
      setHuespedes(datosHuespedes);
      setActiveStep(0);
      setEnviando(false);
    } catch (error) {
      // quitar antes de subir a producción
      console.log(error);
      const response = { error };
      const data = { response };
      if (data?.mensaje_error) {
        setErrorCode(response.mensaje_error);
        setSignature('');
      } else {
        setErrorCode('ERROR');
        setSignature('');
      }
      if (titularRellenando) {
        setActiveStep(2);
      } else {
        setActiveStep(1);
      }
      setEnviando(false);
    }
  };

  React.useEffect(() => {
    if (!esTitular) {
      if (ocrActivo) {
        getTokenNoTitular();
      }
    }
  }, [esTitular]);

  const countryList = [
    { value: 'es', language: 'Español' },
    { value: 'en', language: 'English' },
    { value: 'de', language: 'Deutsche' },
    { value: 'it', language: 'Italiano' },
    { value: 'fr', language: 'Français' },
    { value: 'ca', language: 'Catalá' },
    { value: 'ja', language: '日本語' },
    { value: 'nl', language: 'Nederlands' },
    { value: 'pt', language: 'Português' },
    { value: 'ru', language: 'Русский' },
    { value: 'sv', language: 'Svenska' },
    { value: 'zh', language: '中文' }
  ];

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {mostrarError && (
        <>
          <AppBar
            position='absolute'
            color='default'
            elevation={0}
            sx={{
              position: 'relative',
              borderBottom: (t) => `1px solid ${t.palette.divider}`
            }}
          >
            <Toolbar style={{ backgroundColor: backgroundColor }}>
              <Grid
                container
                direction='row'
                sx={{ display: 'flex', justifyContent: 'center' }}
                alignItems='center'
                justifyContent='center'
              >
                <Grid item xs='auto'>
                  <Typography
                    style={{ marginLeft: '1vh', marginRight: '1vh' }}
                    variant='subtitle1'
                    color='inherit'
                    noWrap
                  >
                    Journeytok
                  </Typography>
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>
          <Container component='main' maxWidth='sm' sx={{ mb: 6 }}>
            <Paper
              style={{
                borderRadius: '3px',
                boxShadow: 'rgb(0 0 0 / 10%) 0px 0px 10px',
                boxSizing: 'border-box'
              }}
              elevation={0}
              sx={{ my: { xs: 3, md: 3 }, p: { xs: 2, md: 3 } }}
            >
              <React.Fragment>
                <React.Fragment>
                  <Typography variant='h6' gutterBottom align='center'>
                    {t('CHECKIN_NO_DISPONIBLE')}
                  </Typography>
                  <Paper elevation={0}>
                    <Grid
                      container
                      sx={{ display: 'flex', justifyContent: 'center' }}
                      direction='row'
                    >
                      <Grid item xs={8} sm={8}>
                        {tipoError !== 'CHECKIN_ERROR_PASADO' && (
                          <Typography gutterBottom align='justify'>
                            {t('CHECKIN_ERROR_GENERICO')}
                          </Typography>
                        )}
                        {tipoError === 'CHECKIN_ERROR_PASADO' && (
                          <Typography gutterBottom align='justify'>
                            {t('CHECKIN_ERROR_PASADO')}
                          </Typography>
                        )}
                      </Grid>
                    </Grid>
                  </Paper>
                </React.Fragment>
              </React.Fragment>
            </Paper>
            <Copyright />
          </Container>
        </>
      )}
      {loading && !mostrarError && (
        <Container
          component='main'
          maxWidth='sm'
          sx={{ mb: 6 }}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            paddingTop: '40vh'
          }}
        >
          <DotLoader
            color='#556EE6'
            loading={loading}
            size={150}
            aria-label='Loading booking info'
            data-testid='booking info'
          />
        </Container>
      )}
      {!loading && !mostrarError && (
        <>
          <AppBar
            position='absolute'
            color='default'
            elevation={0}
            sx={{
              position: 'relative',
              borderBottom: (t) => `1px solid ${t.palette.divider}`
            }}
          >
            <Toolbar style={{ backgroundColor: backgroundColor }}>
              <Grid
                container
                direction='row'
                sx={{ display: 'flex', justifyContent: 'center' }}
                alignItems='center'
                justifyContent='center'
              >
                <Grid item xs='auto'>
                  <div>
                    {logo && (
                      <img
                        height={38}
                        width="auto"
                        alt={'logo'}
                        src={`https://${process.env.GATSBY_LOGO_DOMAIN}/app/Assets/img/logos/${logo}`}
                      />
                    )}
                  </div>
                </Grid>
                <Grid item xs='auto'>
                  <Typography
                    style={{ marginLeft: '1vh', marginRight: '1vh' }}
                    variant='subtitle1'
                    color='inherit'
                    noWrap
                  >
                    {hotelName}
                  </Typography>
                </Grid>
                <Grid item xs='auto'>
                  <FormControl>
                    <Select
                      IconComponent={null}
                      className='select-idioma'
                      sx={{
                        boxShadow: 'none',
                        '.MuiOutlinedInput-notchedOutline': { border: 0 }
                      }}
                      style={{
                        background: 'transparent',
                        border: 'none'
                      }}
                      id='select-idioma'
                      renderValue={() => (
                        <i
                          style={{ fontSize: '20px', cursor: 'pointer' }}
                          className='bx bx-globe'
                        />
                      )}
                      defaultValue={language}
                      label='idioma'
                      onChange={(event) => {
                        handleChangeLanguage(event.target.value);
                      }}
                    >
                      {countryList.map((flag) => (
                        <MenuItem
                          style={{
                            listStyle: 'none',
                            paddingLeft: '2vh',
                            paddingRight: '2vh',
                            backgroundColor: 'rgba(240,248,255, 0.95)'
                          }}
                          value={flag.value}
                          key={flag.value}
                        >
                          {flag.language}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>
          <Container component='main' maxWidth='sm' sx={{ mb: 6 }}>
            <Paper
              style={{
                borderRadius: '3px',
                boxShadow: 'rgb(0 0 0 / 10%) 0px 0px 10px',
                boxSizing: 'border-box'
              }}
              elevation={0}
              sx={{ my: { xs: 3, md: 3 }, p: { xs: 2, md: 3 } }}
            >
              <React.Fragment>
                <React.Fragment>{getStepContent(activeStep)}</React.Fragment>
              </React.Fragment>
            </Paper>
            <Copyright />
          </Container>
        </>
      )}
    </ThemeProvider>
  );
}
