import * as React from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import FormControl from '@mui/material/FormControl';
import Checkbox from '@mui/material/Checkbox';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import TimePicker from '@mui/lab/TimePicker';
import esLocale from 'date-fns/locale/es';
import FormGroup from '@mui/material/FormGroup';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import { capitalize } from '@material-ui/core';
import moment from 'moment';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/material.css';
import { isPossiblePhoneNumber } from 'libphonenumber-js';
import Divider from '@mui/material/Divider';

export default function PersonalData(props) {
  const {
    email,
    phone,
    postalCode,
    arrivalDate,
    datosObligatorios,
    titularRellenando,
    nombreHabitacion
  } = props;
  const { setEmail, setPhone, setPostalCode, setArrivalDate } = props;
  const { privacyPolicy, personalisedOffers, privacyPolicyText, personalisedOffersText } = props;
  const { setPrivacyPolicy, setPersonalisedOffers } = props;
  const { handleNext, handleBack } = props;
  const { errorCode } = props;

  const [openPrivacyPolicy, setOpenPrivacyPolicy] = React.useState(false);
  const [openPersonalisedOffers, setOpenPersonalisedOffers] =
    React.useState(false);
  const [errorEmail, setErrorEmail] = React.useState(false);
  const [errorPhone, setErrorPhone] = React.useState(false);
  const [errorPostalCodel, setErrorPostalCodel] = React.useState(false);
  const [errorArrivalDate, setErrorArrivalDate] = React.useState(false);
  const [errorPrivacyPolicy, setErrorPrivacyPolicy] = React.useState(false);

  const handleClosePrivacyPolicy = () => {
    setOpenPrivacyPolicy(false);
  };

  const handleClosePersonalisedOffers = () => {
    setOpenPersonalisedOffers(false);
  };

  const devuelveTextoPoliticaPrivacidad = () => {
    const activeLanguage = window.localStorage.getItem('gatsby-i18next-language');
    if (privacyPolicyText) {
      if (activeLanguage.toLocaleUpperCase() === "ES") {
        if (privacyPolicyText?.es) {
          return privacyPolicyText.es;
        }
      } else {
        if (privacyPolicyText?.en) {
          return privacyPolicyText.en;
        }
      }
    }
    return t('PRIVACY_POLICY').replace(/\n/gm, '<br>');
  };

  const devuelveTextoOfertasPersonalizadas = () => {
    const activeLanguage = window.localStorage.getItem('gatsby-i18next-language');
    if (personalisedOffersText) {
      if (activeLanguage.toLocaleUpperCase() === "ES") {
        if (personalisedOffersText?.es) {
          return personalisedOffersText.es;
        }
      } else {
        if (personalisedOffersText?.en) {
          return personalisedOffersText.en;
        }
      }
    }
    return '';
  };

  const next = (e) => {
    e.preventDefault();
    let error = false;
    const regex =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    setErrorEmail(false);
    setErrorPhone(false);
    setErrorPostalCodel(false);
    setErrorArrivalDate(false);
    setErrorPrivacyPolicy(false);

    if (datosObligatorios.email) {
      if (!regex.test(email)) {
        setErrorEmail(true);
        error = true;
      }
    }

    if (datosObligatorios.phone) {
      if (!phone || isNaN(phone?.replace('/+', '/'))) {
        setErrorPhone(true);
        error = true;
      } else {
        setErrorPhone(!isPossiblePhoneNumber(`+${phone}`));
      }
    }

    if (datosObligatorios.postalCode) {
      if (!postalCode || isNaN(postalCode?.replace('/+', '/'))) {
        setErrorPostalCodel(true);
        error = true;
      }
    }

    if (datosObligatorios.email) {
      if (!moment(arrivalDate).isValid()) {
        setErrorArrivalDate(true);
        error = true;
      }
    }

    if (!privacyPolicy) {
      setErrorPrivacyPolicy(true);
      error = true;
    }
    if (error) {
      return;
    }

    handleNext();
  };

  const back = (e) => {
    e.preventDefault();
    handleBack();
  };

  const { t } = useTranslation();
  return (
    <React.Fragment>
      {(nombreHabitacion !== '') && (
        <Typography variant='h6' gutterBottom align='center'>
          {nombreHabitacion}
        </Typography>
      )}
      {(nombreHabitacion === '') && (
        <Typography variant='h6' gutterBottom align='center'>
          {t('Personal data')}
        </Typography>
      )}

      <Divider
        variant='middle'
        style={{
          width: '90%',
          margin: 'auto',
          marginTop: 20,
          marginBottom: 20
        }}
      />
      <Dialog
        open={openPrivacyPolicy}
        onClose={handleClosePrivacyPolicy}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>
          {capitalize(t('privacy policy'))}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            <div
              dangerouslySetInnerHTML={{
                __html: devuelveTextoPoliticaPrivacidad()
              }}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClosePrivacyPolicy}>{t('Close')}</Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={openPersonalisedOffers}
        onClose={handleClosePersonalisedOffers}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>
          {capitalize(t('personalised offers'))}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            <div
              dangerouslySetInnerHTML={{
                __html: devuelveTextoOfertasPersonalizadas()
              }}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClosePersonalisedOffers}>{t('Close')}</Button>
        </DialogActions>
      </Dialog>
      <Paper elevation={0} sx={{ pt: 4 }}>
        <Grid container spacing={0} direction='row'>
          {datosObligatorios.email && (
            <Grid container spacing={0} alignItems='center'>
              <Grid item xs={10} sm={7} style={{ margin: 'auto' }}>
                <TextField
                  required={datosObligatorios.email}
                  value={email}
                  id='email'
                  type='email'
                  name='email'
                  label={t('Email')}
                  fullWidth
                  error={errorEmail}
                  helperText={errorEmail ? t('Enter a valid email address') : ''}
                  onChange={(newValue) => {
                    setEmail(newValue.target.value.toLowerCase());
                  }}
                  variant='outlined'
                />
              </Grid>
            </Grid>
          )}

          {datosObligatorios.phone && (
            <Grid container sx={{ pt: 1 }} spacing={0} alignItems='center'>
              <Grid item xs={10} sm={7} style={{ margin: 'auto' }}>
                <PhoneInput
                  country={'es'}
                  value={phone}
                  enableSearch
                  placeholder={t('Phone number')}
                  error={!errorPhone}
                  isValid={!errorPhone}
                  specialLabel={t('Phone number')}
                  fullWidth
                  onChange={(value) => setPhone(value)}
                  inputProps={{
                    name: 'phone',
                    required: datosObligatorios.phone,
                    style: { width: 'inherit' },
                    autoComplete: 'off',
                    form: {
                      autoComplete: 'off',
                    }
                  }}
                />
                {errorPhone && (<TextField
                  className="textfield-telefono-ocultar"
                  required
                  id='phone'
                  name='phone'
                  error={errorPhone}
                  helperText={errorPhone ? t('Enter a valid phone') : ''}
                />)}
              </Grid>
            </Grid>
          )}


          {datosObligatorios.postalCode && (
            <Grid container sx={{ pt: 1 }} spacing={0} alignItems='center'>
              <Grid item xs={10} sm={7} style={{ margin: 'auto' }}>
                <TextField
                  required={datosObligatorios.postalCode}
                  value={postalCode}
                  id='postalCode'
                  type='number'
                  name='postalCode'
                  label={t('Postal code')}
                  fullWidth
                  error={errorPostalCodel}
                  helperText={errorPostalCodel ? t('Enter a postal code') : ''}
                  onChange={(newValue) => {
                    setPostalCode(newValue.target.value);
                  }}
                  variant='outlined'
                />
              </Grid>
            </Grid>
          )}


          {datosObligatorios.arrivalDate && (
            <Grid container sx={{ pt: 1 }} spacing={0} alignItems='center'>
              <Grid item xs={10} sm={7} style={{ margin: 'auto' }}>
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  locale={esLocale}
                >
                  <TimePicker
                    label={t('Arrival hour')}
                    value={arrivalDate}
                    style={{ witdh: '100%' }}
                    onChange={(newValue) => {
                      setArrivalDate(newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        error={errorArrivalDate}
                        helperText={
                          errorArrivalDate ? t('Enter a valid arrival hour') : ''
                        }
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
            </Grid>
          )}

          <Grid container sx={{ pt: 1, pl: 1 }} spacing={0} alignItems='center' style={{ maxWidth: 'inherit' }}>
            <Grid item xs={10} sm={7} style={{ margin: 'auto' }}>
              <FormGroup>
                <FormControl error={errorPrivacyPolicy}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={privacyPolicy}
                        onChange={(event) => {
                          setPrivacyPolicy(event.target.checked);
                        }}
                      />
                    }
                    label={
                      <Typography color='textPrimary'>
                        {t('I accept the ')}
                        <Link
                          href='#'
                          onClick={() => setOpenPrivacyPolicy(true)}
                          style={{
                            color: 'black',
                            textDecorationColor: 'black'
                          }}
                        >
                          {t('privacy policy')}
                        </Link>
                      </Typography>
                    }
                  />
                  {errorPrivacyPolicy && (
                    <FormHelperText>
                      {t('The privacy policy must be accepted')}
                    </FormHelperText>
                  )}
                </FormControl>
                <FormControlLabel
                  style={{ display: titularRellenando ? 'none' : '' }}
                  control={
                    <Checkbox
                      checked={personalisedOffers}
                      onChange={(event) => {
                        setPersonalisedOffers(event.target.checked);
                      }}
                    />
                  }
                  label={
                    <Typography color='textPrimary'>
                      {t('I agree to receive ')}
                      <Link
                        href='#'
                        onClick={() => setOpenPersonalisedOffers(true)}
                        style={{ color: 'black', textDecorationColor: 'black' }}
                      >
                        {t('personalised offers')}
                      </Link>
                    </Typography>
                  }
                />
              </FormGroup>
            </Grid>
          </Grid>
          {!titularRellenando && errorCode && (
            <Grid container sx={{ pt: 1 }} spacing={0} alignItems='center'>
              <Grid item xs={10} sm={7} style={{ margin: 'auto' }}>
                <Typography color='red' variant='caption'>
                  {t(errorCode)}
                </Typography>
              </Grid>
            </Grid>
          )}
          <Grid container spacing={0} direction='row' sx={{ mt: 3 }}>
            <Grid item xs={6} sm={6} style={{ margin: 'auto' }}>
              <React.Fragment>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  {!titularRellenando && (
                    <Button onClick={back}>{t('Back')}</Button>
                  )}
                  <Button variant='contained' onClick={next} color='primary'>
                    {t('Next')}
                  </Button>
                </Box>
              </React.Fragment>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </React.Fragment>
  );
}
