import * as React from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import Button from '@mui/material/Button';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, DesktopDatePicker } from '@mui/x-date-pickers';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import CountrySelect from './CountrySelect';
import dayjs from 'dayjs';
import moment from 'moment';
import axios from 'axios';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { capitalize } from '@material-ui/core';
import countries from './CountryList';
import Typography from '@mui/material/Typography';
import 'dayjs/locale/ca';
import 'dayjs/locale/de';
import 'dayjs/locale/en';
import 'dayjs/locale/es';
import 'dayjs/locale/fr';
import 'dayjs/locale/it';
import 'dayjs/locale/ja';
import 'dayjs/locale/nl';
import 'dayjs/locale/pt';
import 'dayjs/locale/ru';
import 'dayjs/locale/sv';
import 'dayjs/locale/zh-cn';
import AliceOcr from './AliceOcr';
import ProvinceSelect from './ProvinceSelect';

export default function DocumentData(props) {
  const {
    bookingID,
    name,
    lastName,
    lastName2,
    birthDate,
    sex,
    country,
    documentType,
    document,
    province,
    issueDate,
    ocrActivo,
    tokenOcr,
    idCheckin,
    request,
    esTitular,
    expiryDate,
    postalCode,
    titularRellenando,
    city,
    address,
    datosSoloLectura
  } = props;

  const {
    setName,
    setLastName,
    setLastName2,
    setBirthDate,
    setSex,
    setCountry,
    setDocumentType,
    setDocument,
    setProvince,
    setIssueDate,
    setExpiryDate,
    setPostalCode,
    setCity,
    setAddress
  } = props;
  const { errorCode } = props;

  const { handleNext, handleBack } = props;

  const [errorName, setErrorName] = React.useState(false);
  const [errorLastName, setErrorLastName] = React.useState(false);
  const [errorBirthDate, setErrorBirthDate] = React.useState(false);
  const [errorSex, setErrorSex] = React.useState(false);
  const [errorCountry, setErrorCountry] = React.useState(false);
  const [errorDocumentType, setErrorDocumentType] = React.useState(false);
  const [errorDocument, setErrorDocument] = React.useState(false);
  const [errorProvince, setErrorProvince] = React.useState(false);
  const [errorIssueDate, setErrorIssueDate] = React.useState(false);
  const [errorExpiryDate, setErrorExpiryDate] = React.useState(false);
  const [errorPostalCode, setErrorPostalCode] = React.useState(false);
  const [errorCity, setErrorCity] = React.useState(false);
  const [errorAddress, setErrorAddress] = React.useState(false);
  const [openDocumentOcr, setOpenDocumentOcr] = React.useState(false);
  const [uploadDocument, setUploadDocument] = React.useState(false);
  const [uploadDocument2, setUploadDocument2] = React.useState(false);
  const [imagen1, setImagen1] = React.useState('');
  const [imagen2, setImagen2] = React.useState('');
  const [errorReadingDocument, setErrorReadingDocument] = React.useState(false);
  const [aliceDialog, setAliceDialog] = React.useState(false);
  const [disabledFields, setDisabledFields] = React.useState({
    firstName: false,
    lastName: false,
    documento: false
  });

  const activeLanguage = window.localStorage.getItem('gatsby-i18next-language');

  const fileInput1 = React.useRef();
  const fileInput2 = React.useRef();

  const { t } = useTranslation();

  const handleCloseOpenDocumentOcr = () => {
    setOpenDocumentOcr(false);
  };

  const handleCloseErrorReadingDocument = () => {
    setErrorReadingDocument(false);
  };

  const handleCloseUploadDocument = () => {
    setUploadDocument(false);
    setUploadDocument2(true);
  };

  const handleCloseUploadDocument2 = () => {
    setUploadDocument2(false);
  };

  const handleCloseAlice = () => {
    setAliceDialog(false);
  };

  const next = (e) => {
    e.preventDefault();
    let error = false;

    setErrorName(false);
    setErrorLastName(false);
    setErrorBirthDate(false);
    setErrorSex(false);
    setErrorCountry(false);
    setErrorDocumentType(false);
    setErrorDocument(false);
    setErrorProvince(false);
    setErrorAddress(false);
    setErrorCity(false);
    setErrorIssueDate(false);

    if (!name) {
      setErrorName(true);
      error = true;
    }

    if (!lastName) {
      setErrorLastName(true);
      error = true;
    }

    if (!moment(birthDate).isValid() || !moment(birthDate).isBefore()) {
      setErrorBirthDate(true);
      error = true;
    }

    if (!sex) {
      setErrorSex(true);
      error = true;
    }

    if (!country) {
      setErrorCountry(true);
      error = true;
    }

    if (!documentType) {
      setErrorDocumentType(true);
      error = true;
    }

    if (!document) {
      setErrorDocument(true);
      error = true;
    } else {
      if (documentType === 'id') {
        if (country?.alpha3?.toLowerCase() === 'esp') {
          const valido = validarDNI(document.toUpperCase());
          setErrorDocument(!valido);
          if (!valido) {
            error = true;
          }
        }
      }
    }

    if (!province) {
      setErrorProvince(true);
      error = true;
    } else {
      if (country.alpha3.toLowerCase() === 'esp') {
        const valido = validadProvicinciaSpain(province);
        setErrorProvince(!valido);
        if (!valido) {
          error = true;
        }
      }
    }

    if (!postalCode && titularRellenando) {
      setErrorPostalCode(true);
      error = true;
    }

    if (!city) {
      setErrorCity(true);
      error = true;
    }

    if (!address) {
      setErrorAddress(true);
      error = true;
    }

    if (!moment(issueDate).isValid() || !moment(issueDate).isBefore()) {
      setErrorIssueDate(true);
      error = true;
    }

    if (!moment(expiryDate).isValid()) {
      setErrorExpiryDate(true);
      error = true;
    }

    if (error) {
      return;
    }
    handleNext();
  };

  React.useEffect(()=> {
    const auxDisabledFields = structuredClone(disabledFields);
    if (datosSoloLectura.firstName && name !== '' && esTitular) {
      auxDisabledFields.firstName = true;
    } else {
      auxDisabledFields.firstName = false;
    }
    if (datosSoloLectura.lastName && lastName !== '' && esTitular) {
      auxDisabledFields.lastName = true;
    } else {
      auxDisabledFields.lastName = false;
    }
    if (datosSoloLectura.documento && document !== '' && esTitular) {
      auxDisabledFields.documento = true;
    } else {
      auxDisabledFields.documento = false;
    }
    setDisabledFields(auxDisabledFields);
  }, []);

  const validadProvicinciaSpain = (provincia) => {
    let provinciaNormalizada = provincia
      ?.trim()
      ?.normalize('NFD')
      ?.replace(/[\u0300-\u036f]/g, '');
    provinciaNormalizada = provinciaNormalizada.toLowerCase();
    const provincias = [
      'alava',
      'albacete',
      'alicante',
      'almeria',
      'asturias',
      'avila',
      'badajoz',
      'barcelona',
      'burgos',
      'caceres',
      'cadiz',
      'cantabria',
      'castellon',
      'ciudad real',
      'cordoba',
      'cuenca',
      'gerona',
      'granada',
      'guadalajara',
      'guipuzcoa',
      'huelva',
      'huesca',
      'islas baleares',
      'jaen',
      'la coruna',
      'la rioja',
      'las palmas',
      'leon',
      'lerida',
      'lugo',
      'madrid',
      'malaga',
      'murcia',
      'navarra',
      'orense',
      'palencia',
      'pontevedra',
      'salamanca',
      'santa cruz de tenerife',
      'segovia',
      'sevilla',
      'soria',
      'tarragona',
      'teruel',
      'toledo',
      'valencia',
      'valladolid',
      'vizcaya',
      'zamora',
      'zaragoza',
      'ceuta',
      'melilla'
    ];

    return provincias.includes(provinciaNormalizada);
  };

  const validarDNI = (dni) => {
    const DNI = dni.padStart(9, '0');
    let numero, letr, letra;
    const expresion_regular_dni = /^[XYZ]?\d{5,8}[A-Z]$/;

    if (expresion_regular_dni.test(DNI) === true) {
      numero = DNI.substr(0, DNI.length - 1);
      letr = DNI.substr(DNI.length - 1, 1);
      numero = numero % 23;
      letra = 'TRWAGMYFPDXBNJZSQVHLCKET';
      letra = letra.substring(numero, numero + 1);

      if (letra != letr.toUpperCase()) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const sendDocument = async () => {
    handleCloseUploadDocument2();
    if (imagen1 === '' && imagen2 === '') {
      return;
    }
    const body = { bookingID, imagen1, imagen2 };
    try {
      setOpenDocumentOcr(true);
      const result = await axios.post('/apiCheckin/getDocumentData', body);
      const { data } = result;
      const { error } = data;
      if (error) {
        throw Error('ERROR');
      }
      if (data) {
        setName(capitalize(data.name?.toLowerCase()));
        setLastName(capitalize(data.lastName?.toLowerCase()));
        setLastName2(capitalize(data.lastName2?.toLowerCase()));
        setCountry(
          countries.filter(
            (country) => country.alpha3 === data.country.toLowerCase()
          )[0]
        );
        setBirthDate(moment(data.birth_date, 'DD/MM/YY').toDate());
        setIssueDate(moment(data.issue_date, 'DD/MM/YY').toDate());
        setSex(data.sex);
        // setDocumentType(data.document_type.toLowerCase());
        setDocument(data.document);
      } else {
        setErrorReadingDocument(true);
      }
    } catch (error) {
      setErrorReadingDocument(true);
      // quitar antes de subir a producción
      console.log(error);
    }
  };

  const getDocumentData = async (userAlice) => {
    try {
      setOpenDocumentOcr(true);
      const result = await axios.get(
        `/apiCheckin/getDocumentReport?idCheckin=${idCheckin}&request=${request}&titular=${esTitular}&userId=${userAlice}`
      );
      const { data } = result;
      const { datosDocumento, error } = data;
      if (error) {
        throw Error('No data');
      }
      if (datosDocumento) {
        if (disabledFields.firstName) {
          if (datosDocumento?.name) {
            setName(capitalize(datosDocumento.name?.toLowerCase()));
          }
        }
        if (disabledFields.lastName) {
          if (datosDocumento?.lastName1) {
            setLastName(capitalize(datosDocumento.lastName1?.toLowerCase()));
          } else if (datosDocumento?.lastName) {
            setLastName(capitalize(datosDocumento.lastName.toLowerCase()));
          }
          if (datosDocumento?.lastName2) {
            setLastName2(capitalize(datosDocumento.lastName2?.toLowerCase()));
          }
        }
        if (datosDocumento?.country) {
          setCountry(
            countries.filter(
              (country) =>
                country.alpha3 === datosDocumento.country.toLowerCase()
            )[0]
          );
        }
        if (datosDocumento?.birth_date) {
          setBirthDate(
            moment(datosDocumento.birth_date, 'DD/MM/YYYY').toDate()
          );
        }
        if (datosDocumento?.issue_date) {
          setIssueDate(
            moment(datosDocumento.issue_date, 'DD/MM/YYYY').toDate()
          );
        }
        if (datosDocumento?.expiry_date) {
          setExpiryDate(
            moment(datosDocumento.expiry_date, 'DD/MM/YYYY').toDate()
          );
        }
        if (datosDocumento?.sex) {
          setSex(datosDocumento.sex);
        }
        if (datosDocumento?.province) {
          setProvince(datosDocumento.province);
        }
        if (datosDocumento?.city) {
          setCity(datosDocumento.city);
        }
        if (datosDocumento?.address) {
          setAddress(datosDocumento.address);
        }
        if (disabledFields.documento) {
          if (datosDocumento?.document_type) {
            setDocumentType(datosDocumento.document_type.toLowerCase());
          }
          if (datosDocumento?.document) {
            setDocument(datosDocumento.document);
          }
        }
      } else {
        setErrorReadingDocument(true);
      }
    } catch (error) {
      setErrorReadingDocument(true);
      // quitar antes de subir a producción
      console.log(error);
    }
  };

  const handleFileInput = async (event) => {
    if (uploadDocument) {
      console.log('cargo el anverso o el pasaporte');
      const result64 = await convertBase64(event.target.files[0]);
      setImagen1(result64);
      handleCloseUploadDocument();
    }
    if (uploadDocument2) {
      console.log('cargo el reverso');
      const result64 = await convertBase64(event.target.files[0]);
      setImagen2(result64);
      handleCloseUploadDocument2();
      sendDocument();
    }
  };

  const back = (e) => {
    e.preventDefault();
    handleBack();
  };

  return (
    <React.Fragment>
      {/* {ocrActivo && (
        <>
          <Paper elevation={0} sx={{ pb: 4 }}>
            <Grid container direction='row'>
              <Grid
                item
                xs={6}
                sm={6}
                style={{ margin: 'auto', textAlign: 'center' }}
              >
                <Button
                  variant='contained'
                  component='span'
                  onClick={() => setUploadDocument(true)}
                  style={{ backgroundColor: '#ffbd21', textAlign: 'center' }}
                >
                  {t('Scan document')}
                </Button>
              </Grid>
            </Grid>
          </Paper>
          <AliceOcr setAliceDialog={setAliceDialog} />
          <Dialog
            open={uploadDocument2}
            onClose={handleCloseUploadDocument2}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
          >
            <DialogTitle id='alert-dialog-title'>
              {capitalize(t('Upload document'))}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id='alert-dialog-description'>
                {t(
                  'Press upload or if you are using your ID press upload back side for uploading the back side'
                )}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <label htmlFor='contained-button-file'>
                <Input
                  ref={fileInput1}
                  accept='image/*'
                  id='contained-button-file'
                  multiple
                  type='file'
                  style={{ display: 'none' }}
                  onChange={handleFileInput}
                />
                <Button onClick={() => fileInput1.current.click()} component='label'>
                  {t('Upload back side')}
                </Button>
                <Button onClick={() => sendDocument()} component='label'>
                  {t('Upload')}
                </Button>
              </label>
            </DialogActions>
          </Dialog>
          <Dialog
            open={uploadDocument}
            onClose={handleCloseUploadDocument2}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
          >
            <DialogTitle id='alert-dialog-title'>
              {capitalize(t('Upload document'))}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id='alert-dialog-description'>
                {t(
                  'Prepare your passport or if you are using your ID card upload the front side'
                )}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <label htmlFor='contained-button-file'>
                <Input
                  ref={fileInput2}
                  accept='image/*'
                  id='contained-button-file'
                  multiple
                  type='file'
                  style={{ display: 'none' }}
                  onChange={handleFileInput}
                />
                <Button onClick={() => fileInput2.current.click()} component='label'>
                  {t('Upload')}
                </Button>
              </label>
            </DialogActions>
          </Dialog>
          <Dialog
            open={aliceDialog}
            onClose={handleCloseAlice}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
          >
            <DialogTitle id='alert-dialog-title'>
              {capitalize(t('Upload document'))}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id='alert-dialog-description'>
                <div id="alice"></div>
              </DialogContentText>
            </DialogContent>
          </Dialog>
          <Dialog
            open={openDocumentOcr}
            onClose={handleCloseOpenDocumentOcr}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
          >
            <DialogTitle id='alert-dialog-title'>
              {capitalize(t('Reading document'))}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id='alert-dialog-description'>
                {t('Please check your data')}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseOpenDocumentOcr}>{t('Accept')}</Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={errorReadingDocument}
            onClose={handleCloseErrorReadingDocument}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
          >
            <DialogTitle id='alert-dialog-title'>
              {capitalize(t('Reading document'))}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id='alert-dialog-description'>
                {t('Error reading your document, try again or enter data manually')}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseErrorReadingDocument}>{t('Accept')}</Button>
            </DialogActions>
          </Dialog>

          <Divider variant='middle' style={{ width: '90%', margin: 'auto' }} />
        </>
      )} */}

      {ocrActivo && (
        <>
          <AliceOcr
            tokenOcr={tokenOcr}
            setAliceDialog={setAliceDialog}
            getDocumentData={getDocumentData}
            setDocumentType={setDocumentType}
          />
          <Dialog
            fullScreen
            open={aliceDialog}
            onClose={handleCloseAlice}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
          >
            <DialogTitle id='alert-dialog-title'>
              {capitalize(t('Upload document'))}
            </DialogTitle>
            <DialogContent>
              <div id='alice'></div>
              <DialogActions>
                <Button onClick={handleCloseAlice}>{t('Close')}</Button>
              </DialogActions>
            </DialogContent>
          </Dialog>
          <Dialog
            open={errorReadingDocument}
            onClose={handleCloseErrorReadingDocument}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
          >
            <DialogTitle id='alert-dialog-title'>
              {capitalize(t('Reading document'))}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id='alert-dialog-description'>
                {t(
                  'Error reading your document, try again or enter data manually'
                )}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseErrorReadingDocument}>
                {t('Accept')}
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={openDocumentOcr}
            onClose={handleCloseOpenDocumentOcr}
            aria-labelledby='alert-dialog-title'
            aria-describedby='alert-dialog-description'
          >
            <DialogTitle id='alert-dialog-title'>
              {capitalize(t('Reading document'))}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id='alert-dialog-description'>
                {t('Please check your data')}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleCloseOpenDocumentOcr}>
                {t('Accept')}
              </Button>
            </DialogActions>
          </Dialog>

          <Divider variant='middle' style={{ width: '90%', margin: 'auto' }} />
        </>
      )}

      <Paper elevation={0} sx={{ pt: 8 }}>
        <Grid container spacing={0} direction='row'>
          <Grid container spacing={0} alignItems='center'>
            <Grid item xs={10} sm={7} style={{ margin: 'auto' }}>
              <TextField
                required
                value={name}
                id='name'
                type='name'
                name='name'
                label={t('Name')}
                fullWidth
                error={errorName}
                disabled={disabledFields.firstName}
                helperText={errorName ? t('Enter a name') : ''}
                onChange={(newValue) => {
                  setName(newValue.target.value);
                }}
                variant='outlined'
              />
            </Grid>
          </Grid>

          <Grid container sx={{ pt: 1 }} spacing={0} alignItems='center'>
            <Grid item xs={10} sm={7} style={{ margin: 'auto' }}>
              <TextField
                required
                value={lastName}
                id='lastName'
                type='text'
                name='lastName'
                label={t('Last name')}
                fullWidth
                disabled={disabledFields.lastName}
                error={errorLastName}
                helperText={errorLastName ? t('Enter a last name') : ''}
                onChange={(newValue) => {
                  setLastName(newValue.target.value);
                }}
                variant='outlined'
              />
            </Grid>
          </Grid>

          <Grid container sx={{ pt: 1 }} spacing={0} alignItems='center'>
            <Grid item xs={10} sm={7} style={{ margin: 'auto' }}>
              <TextField
                value={lastName2}
                id='lastName2'
                type='text'
                name='lastName'
                label={t('Last name') + '2'}
                fullWidth
                disabled={disabledFields.lastName}
                onChange={(newValue) => {
                  setLastName2(newValue.target.value);
                }}
                variant='outlined'
              />
            </Grid>
          </Grid>

          <Grid container sx={{ pt: 1 }} spacing={0} alignItems='center'>
            <Grid item xs={10} sm={7} style={{ margin: 'auto' }}>
              <LocalizationProvider
                adapterLocale={activeLanguage}
                dateAdapter={AdapterDayjs}
              >
                <DesktopDatePicker
                  label={t('Birth date')}
                  inputFormat='dd/MM/yyyy'
                  style={{ width: '100% !important' }}
                  minDate={dayjs(new Date(1900, 0, 1))}
                  disableFuture
                  value={dayjs(birthDate)}
                  onChange={(newValue) => {
                    if (newValue) {
                      setBirthDate(newValue.toDate());
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      error={errorBirthDate}
                      helperText={errorBirthDate ? t('Enter a valid date') : ''}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>

          <Grid container sx={{ pt: 1 }} spacing={0} alignItems='center'>
            <Grid item xs={10} sm={7} style={{ margin: 'auto' }}>
              <FormControl fullWidth error={errorSex}>
                <InputLabel id='sex'>{t('Sex')}</InputLabel>
                <Select
                  labelId='sex-select'
                  id='sex'
                  defaultValue=''
                  required
                  value={sex}
                  label={t('Sex')}
                  onChange={(newValue) => {
                    setSex(newValue.target.value);
                  }}
                >
                  <MenuItem value={'male'}>{t('Male')}</MenuItem>
                  <MenuItem value={'female'}>{t('Female')}</MenuItem>
                </Select>
                {errorSex && (
                  <FormHelperText>{t('Enter a valid sex')}</FormHelperText>
                )}
              </FormControl>
            </Grid>
          </Grid>

          <Grid container sx={{ pt: 1 }} spacing={0} alignItems='center'>
            <Grid item xs={10} sm={7} style={{ margin: 'auto' }}>
              <CountrySelect
                country={country}
                error={errorCountry}
                helperText={errorCountry ? t('Enter a country') : ''}
                setCountry={setCountry}
              ></CountrySelect>
              {errorCountry && (
                <FormHelperText style={{ color: '#d32f2f' }}>{t('Enter a country')}</FormHelperText>
              )}
            </Grid>
          </Grid>
          {country?.alpha3 === 'esp' && (
            <Grid container sx={{ pt: 1 }} spacing={0} alignItems='center'>
              <Grid item xs={10} sm={7} style={{ margin: 'auto' }}>
                <ProvinceSelect province={province} setProvince={setProvince} />
                {errorProvince && (
                  t('Enter a province')
                )}
              </Grid>
            </Grid>
          )}
          {country?.alpha3 !== 'esp' && (
            <Grid container sx={{ pt: 1 }} spacing={0} alignItems='center'>
              <Grid item xs={10} sm={7} style={{ margin: 'auto' }}>
                <TextField
                  id='province'
                  required
                  value={province}
                  type='text'
                  name='province'
                  label={t('Province')}
                  fullWidth
                  error={errorProvince}
                  helperText={errorProvince ? t('Enter a province') : ''}
                  onChange={(newValue) => {
                    setProvince(newValue.target.value);
                  }}
                  variant='outlined'
                />
              </Grid>
            </Grid>
          )}
          <Grid container sx={{ pt: 1 }} spacing={0} alignItems='center'>
            <Grid item xs={10} sm={7} style={{ margin: 'auto' }}>
              <TextField
                id='city'
                required
                value={city}
                type='text'
                name='city'
                label={t('City')}
                fullWidth
                error={errorCity}
                helperText={errorCity ? t('Enter a city') : ''}
                onChange={(newValue) => {
                  setCity(newValue.target.value);
                }}
                variant='outlined'
              />
            </Grid>
          </Grid>
          {titularRellenando && (
            <Grid container sx={{ pt: 1 }} spacing={0} alignItems='center'>
              <Grid item xs={10} sm={7} style={{ margin: 'auto' }}>
                <TextField
                  required={titularRellenando}
                  value={postalCode}
                  id='postalCode'
                  type='number'
                  name='postalCode'
                  label={t('Postal code')}
                  fullWidth
                  error={errorPostalCode}
                  helperText={errorPostalCode ? t('Enter a postal code') : ''}
                  onChange={(newValue) => {
                    setPostalCode(newValue.target.value);
                  }}
                  variant='outlined'
                />
              </Grid>
            </Grid>
          )}

          <Grid container sx={{ pt: 1 }} spacing={0} alignItems='center'>
            <Grid item xs={10} sm={7} style={{ margin: 'auto' }}>
              <TextField
                required
                value={address}
                id='address'
                type='text'
                name='address'
                label={t('Address')}
                fullWidth
                error={errorAddress}
                helperText={errorAddress ? t('Enter an address') : ''}
                onChange={(newValue) => {
                  setAddress(newValue.target.value);
                }}
                variant='outlined'
              />
            </Grid>
          </Grid>

          <Grid container sx={{ pt: 1 }} spacing={0} alignItems='center'>
            <Grid item xs={10} sm={7} style={{ margin: 'auto' }}>
              <FormControl fullWidth error={errorDocumentType}>
                <InputLabel id='documentType'>{t('Document type')}</InputLabel>
                <Select
                  labelId='document-type-select'
                  id='documentType'
                  defaultValue=''
                  required
                  value={documentType}
                  disabled={disabledFields.documento}
                  label={t('Document type')}
                  onChange={(newValue) => {
                    setDocumentType(newValue.target.value);
                  }}
                >
                  <MenuItem value={'id'}>{t('ID')}</MenuItem>
                  <MenuItem value={'passport'}>{t('Passport')}</MenuItem>
                </Select>
                {errorDocumentType && (
                  <FormHelperText>{t('Enter a document type')}</FormHelperText>
                )}
              </FormControl>
            </Grid>
          </Grid>

          <Grid container sx={{ pt: 1 }} spacing={0} alignItems='center'>
            <Grid item xs={10} sm={7} style={{ margin: 'auto' }}>
              <TextField
                id='document'
                required
                value={document}
                type='text'
                name='document'
                label={t('Document')}
                fullWidth
                disabled={disabledFields.documento}
                error={errorDocument}
                placeholder='00000XXXX'
                helperText={errorDocument ? t('Enter a valid document') : ''}
                onChange={(newValue) => {
                  setErrorDocument(false);
                  const valor = newValue.target.value.toUpperCase()
                                .replaceAll('.', '')
                                .replaceAll(',', '')
                                .replaceAll(' ', '')
                                .replaceAll('-', '')
                                .replaceAll('_', '');
                  setDocument(valor);
                }}
                variant='outlined'
              />
            </Grid>
          </Grid>

          <Grid container sx={{ pt: 1 }} spacing={0} alignItems='center'>
            <Grid item xs={10} sm={7} style={{ margin: 'auto', width: '100%' }}>
              <LocalizationProvider
                adapterLocale={activeLanguage}
                dateAdapter={AdapterDayjs}
              >
                <DesktopDatePicker
                  label={t('Issue date')}
                  inputFormat='dd/MM/yyyy'
                  style={{ width: '100% !important' }}
                  minDate={dayjs(new Date(1950, 0, 1))}
                  disableFuture
                  value={dayjs(issueDate)}
                  onChange={(newValue) => {
                    if (newValue) {
                      setIssueDate(newValue.toDate());
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      style={{ width: '100% !important' }}
                      error={errorIssueDate}
                      helperText={errorIssueDate ? t('Enter a valid date') : ''}
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>

          <Grid container sx={{ pt: 1 }} spacing={0} alignItems='center'>
            <Grid item xs={10} sm={7} style={{ margin: 'auto' }}>
              <LocalizationProvider
                adapterLocale={activeLanguage}
                dateAdapter={AdapterDayjs}
              >
                <DesktopDatePicker
                  label={t('Expiry date')}
                  inputFormat='dd/MM/yyyy'
                  style={{ width: '100% !important' }}
                  minDate={dayjs(new Date(1980, 0, 1))}
                  value={dayjs(expiryDate)}
                  onChange={(newValue) => {
                    if (newValue) {
                      setExpiryDate(newValue.toDate());
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      error={errorExpiryDate}
                      helperText={
                        errorExpiryDate ? t('Enter a valid date') : ''
                      }
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
          </Grid>

          {errorCode && (
            <Grid container sx={{ pt: 1 }} spacing={0} alignItems='center'>
              <Grid item xs={10} sm={7} style={{ margin: 'auto' }}>
                <Typography color='red' variant='caption'>
                  {t(errorCode)}
                </Typography>
              </Grid>
            </Grid>
          )}

          <Grid container spacing={0} direction='row' sx={{ mt: 3 }}>
            <Grid item xs={6} sm={6} style={{ margin: 'auto' }}>
              <React.Fragment>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Button onClick={back}>{t('Back')}</Button>
                  <Button variant='contained' onClick={next} color='primary'>
                    {t('Next')}
                  </Button>
                </Box>
              </React.Fragment>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </React.Fragment>
  );
}
