import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import countries from './CountryList';

const LANGUAGES = ['de', 'es', 'en', 'fr', 'it', 'ja', 'nl', 'pt', 'ru', 'sv', 'zh'];

export default function CountrySelect(props) {
  const { country } = props;
  const { setCountry } = props;
  const { t } = useTranslation();

  const activeLanguage = window.localStorage.getItem('gatsby-i18next-language');
  let languageContries = 'en';
  if (LANGUAGES.includes(activeLanguage)) {
    languageContries = activeLanguage;
  } else {
    if (activeLanguage === 'ca') {
      languageContries = 'es';
    }
  }

  return (
    <Autocomplete
      id='country-select'
      value={country}
      options={countries}
      autoHighlight
      isOptionEqualToValue={(option, value) => option.alpha3.toLowerCase() === value.alpha3.toLowerCase()}
      onChange={(event, value) => {
        setCountry(value);
      }}
      getOptionLabel={(option) => option[languageContries]}
      renderOption={(props, option) => (
        <Box
          component='li'
          sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
          {...props}
        >
          <img
            loading='lazy'
            width='20'
            src={`https://flagcdn.com/w20/${option.alpha2.toLowerCase()}.png`}
            srcSet={`https://flagcdn.com/w40/${option.alpha2.toLowerCase()}.png 2x`}
            alt=''
          />
          {option[languageContries]}
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label={t('Country')}
          inputProps={{
            ...params.inputProps
          }}
        />
      )}
    />
  );
}
