import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import moment from 'moment';

export default function Start(props) {
  const {
    hotelName,
    bookingID,
    arrivalDate,
    departure,
    adults,
    children,
    huespedes
  } = props;
  const {
    handleNext,
    setEsTitular,
    inicio,
    setIdUsuarios,
    setNombreHabitacion
  } = props;
  const { t } = useTranslation();

  const start = (e) => {
    e.preventDefault();
    handleNext();
  };

  const handleShare = async () => {
    if (navigator.share) {
      try {
        await navigator.share({
          title: t('Checkin online'),
          text: t('Checkin online'),
          url: window.location.href
        });
      } catch (error) {
        console.error('Error al compartir el contenido:', error);
      }
    }
  };

  return (
    <React.Fragment>
      <Typography variant='h6' gutterBottom align='center'>
        {t('Checkin online')}
      </Typography>

      <Divider
        variant='middle'
        style={{ width: '90%', margin: 'auto', paddingTop: 20 }}
      />

      <Paper elevation={0}>
        <Grid container sx={{ pt: 4 }}>
          <Grid
            container
            sx={{ display: 'flex', justifyContent: 'center' }}
            direction='row'
          >
            <Grid item xs={5} sm={4}>
              <Typography gutterBottom align='justify'>
                {t('Hotel')}:
              </Typography>
            </Grid>
            <Grid item xs={5} sm={4}>
              <Typography gutterBottom align='justify'>
                {hotelName}
              </Typography>
            </Grid>
          </Grid>

          <Grid
            container
            sx={{ display: 'flex', justifyContent: 'center' }}
            direction='row'
          >
            <Grid item xs={5} sm={4}>
              <Typography gutterBottom align='justify'>
                {t('Booking ID')}:
              </Typography>
            </Grid>
            <Grid item xs={5} sm={4}>
              <Typography gutterBottom align='justify'>
                {bookingID}
              </Typography>
            </Grid>
          </Grid>

          <Grid
            container
            sx={{ display: 'flex', justifyContent: 'center' }}
            direction='row'
          >
            <Grid item xs={5} sm={4}>
              <Typography gutterBottom align='justify'>
                {t('Arrival')}:
              </Typography>
            </Grid>
            <Grid item xs={5} sm={4}>
              <Typography gutterBottom align='justify'>
                {moment(arrivalDate).format('DD/MM/YYYY')}
              </Typography>
            </Grid>
          </Grid>

          <Grid
            container
            sx={{ display: 'flex', justifyContent: 'center' }}
            direction='row'
          >
            <Grid item xs={5} sm={4}>
              <Typography gutterBottom align='justify'>
                {t('Departure')}:
              </Typography>
            </Grid>
            <Grid item xs={5} sm={4}>
              <Typography gutterBottom align='justify'>
                {moment(departure).format('DD/MM/YYYY')}
              </Typography>
            </Grid>
          </Grid>

          <Grid
            container
            sx={{ display: 'flex', justifyContent: 'center' }}
            direction='row'
          >
            <Grid item xs={5} sm={4}>
              <Typography gutterBottom align='justify'>
                {t('Adults')}:
              </Typography>
            </Grid>
            <Grid item xs={5} sm={4}>
              <Typography gutterBottom align='justify'>
                {adults}
              </Typography>
            </Grid>
          </Grid>

          <Grid
            container
            sx={{ display: 'flex', justifyContent: 'center' }}
            direction='row'
          >
            <Grid item xs={5} sm={4}>
              <Typography gutterBottom align='justify'>
                {t('Children')}:
              </Typography>
            </Grid>
            <Grid item xs={5} sm={4}>
              <Typography gutterBottom align='justify'>
                {children}
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <Divider
          variant='middle'
          style={{ width: '90%', margin: 'auto', paddingTop: 20 }}
        />
        <Grid container justifyContent='center' padding={1}>
          <Grid item xs={10}>
            <Grid container alignItems='center' spacing={2}>
              <Grid item xs={6}>
                <Typography variant='subtitle2'>
                  {t('Checkin adults')}:
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {huespedes?.map((h) => {
          return (
            <Grid
              key={h.nombre}
              container
              justifyContent='center'
              padding={1}
              style={{
                width: '80%',
                marginLeft: 'auto',
                marginRight: 'auto',
                marginTop: '2vh',
                borderRadius: '6px',
                boxShadow:
                  'rgba(9, 30, 66, 0.25) 0px 1px 1px, rgba(9, 30, 66, 0.13) 0px 0px 1px 1px'
              }}
            >
              <Grid item xs={12}>
                <Grid container alignItems='center'>
                  <Grid item md={2} xs={2} sx={{ px: 1 }}>
                    <i
                      style={{ fontSize: '20px', cursor: 'pointer' }}
                      className='bx bx-user'
                    />
                  </Grid>
                  <Grid item md={6} xs={4} sx={{ px: 1 }}>
                    <Typography variant='subtitle2'>{h.nombre}</Typography>
                  </Grid>
                  {h.completo && (
                    <Grid item md={4} xs={6} sx={{ px: 1 }}>
                      <Button variant='contained' color='success'>
                        <i
                          style={{
                            fontSize: '20px',
                            cursor: 'pointer',
                            paddingRight: '1vh'
                          }}
                          className='bx bx-check-circle'
                        />
                        <Typography variant='caption'>
                          {t('Check-in')}
                        </Typography>
                      </Button>
                    </Grid>
                  )}
                  {!h.completo && (
                    <Grid item md={4} xs={6} sx={{ px: 1 }}>
                      <Button
                        onClick={(e) => {
                          setEsTitular(h.titular);
                          setNombreHabitacion(h.nombre);
                          if (h.id_usuarios) {
                            setIdUsuarios(h.id_usuarios);
                          } else {
                            setIdUsuarios(0);
                          }
                          if (!h.titular) {
                            inicio();
                          }
                          start(e);
                        }}
                        variant='contained'
                        color='primary'
                        size='small'
                      >
                        <i
                          style={{
                            fontSize: '20px',
                            cursor: 'pointer',
                            paddingRight: '1vh'
                          }}
                          className='bx bx-plus-circle'
                        />
                        <Typography variant='caption'>
                          {t('Check-in')}
                        </Typography>
                      </Button>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          );
        })}

        <Grid container justifyContent='right' padding={1}>
          <Grid item xs={3}>
                <Button onClick={handleShare}>{t('Share')}</Button>
          </Grid>
        </Grid>

      </Paper>
    </React.Fragment>
  );
}
