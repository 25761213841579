import * as React from 'react';
import 'aliceonboarding/dist/aliceonboarding.css';
import {
  DocumentType,
  Onboarding,
  OnboardingConfig,
  Authenticator,
  DocumentCapturerType,
  DocumentStageConfig,
  CameraType
} from 'aliceonboarding';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import { capitalize } from '@material-ui/core';
import './../styles.css';

export default function AliceOcr(props) {
  const { tokenOcr, setAliceDialog, getDocumentData, setDocumentType } = props;
  const [aliceDocumentDialog, setAliceDocumentDialog] = React.useState(false);

  const { t } = useTranslation();

  const onCancel = () => {
    // eliminar antes subir
    console.log('onCancel');
    setAliceDialog(false);
  };

  const onSuccess = (userInfo) => {
    // eliminar antes subir
    console.log('onSuccess: ' + userInfo);
    console.log('onSuccess: ' + JSON.stringify(userInfo));
    setAliceDialog(false);
    getDocumentData(userInfo?.user_id);
  };

  const onFailure = (error) => {
    // eliminar antes subir
    console.log('onFailure: ' + error);
    console.log('onFailure: ' + JSON.stringify(error));
    setAliceDialog(false);
  };

  const handleCloseAliceDocument = () => {
    setAliceDocumentDialog(false);
  };

  const startKYC = (documentType = 'ID') => {
    handleCloseAliceDocument();
    let authenticator = new Authenticator();
    authenticator
      .execute()
      .then(() => {
        setAliceDialog(true);
        console.log('Authentication was successful');
        const completeTimeout = 1;
        let documentStageConfig = new DocumentStageConfig({
          capturerType: DocumentCapturerType.ALL,
          automaticCapture: true,
          cameraType: CameraType.BACK
        });
        let config = new OnboardingConfig().withUserToken(tokenOcr);
        if (documentType === 'ID') {
          config = config.withAddDocumentStage({
            documentType: DocumentType.IDCARD,
            documentStageConfig: documentStageConfig
          });
        }
        if (documentType === 'PASSPORT') {
          config = config.withAddDocumentStage({
            documentType: DocumentType.PASSPORT,
            documentStageConfig: documentStageConfig
          });
        }
        if (documentType === 'DRIVERLICENSE') {
          config = config.withAddDocumentStage({
            documentType: DocumentType.DRIVERLICENSE,
            documentStageConfig: documentStageConfig
          });
        }
        config = config.withCustomLocalization({
          language: window.localStorage.getItem('gatsby-i18next-language')
        });
        config = config.withCustomParameters(completeTimeout);
        if (process.env.GATSBY_ENTORNO_OCR !== 'produccion') {
          Onboarding.setEnvironment('sandbox');
        }
        new Onboarding({ idSelector: 'alice', onboardingConfig: config }).run(
          onSuccess,
          onFailure,
          onCancel
        );
      })
      .catch((error) => {
        console.log('Error on authentication: ' + error);
      });
  };

  return (
    <React.Fragment>
      <Paper elevation={0} sx={{ pb: 4 }}>
        <Grid container direction='row'>
          <Grid
            item
            xs={6}
            sm={6}
            style={{ margin: 'auto', textAlign: 'center' }}
          >
            <Button
              onClick={() => setAliceDocumentDialog(true)}
              variant='contained'
              component='span'
              style={{ backgroundColor: '#ffbd21', textAlign: 'center' }}
            >
              {t('Scan document')}
            </Button>
          </Grid>
        </Grid>
        <Dialog
          open={aliceDocumentDialog}
          onClose={handleCloseAliceDocument}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle id='alert-dialog-title'>
            {capitalize(t('Select the document type'))}
          </DialogTitle>
          <DialogContent>
            <List sx={{ pt: 0 }}>
              <ListItem disableGutters key={'passport'}>
                <ListItemButton
                  style={{
                    backgroundColor: '#003FA2',
                    borderRadius: '0.4rem',
                    color: 'white',
                    textAlign: 'center'
                  }}
                  onClick={() => {
                    startKYC('PASSPORT');
                    setDocumentType('passport');
                  }}
                >
                  <ListItemText primary={t('Passport')} />
                </ListItemButton>
              </ListItem>
              <ListItem disableGutters key={'id'}>
                <ListItemButton
                  style={{
                    backgroundColor: '#003FA2',
                    borderRadius: '0.4rem',
                    color: 'white',
                    textAlign: 'center'
                  }}
                  onClick={() => {
                    startKYC('ID');
                    setDocumentType('id');
                  }}
                >
                  <ListItemText primary={t('ID')} />
                </ListItemButton>
              </ListItem>
              <ListItem disableGutters key={'driverlicense'}>
                <ListItemButton
                  style={{
                    backgroundColor: '#003FA2',
                    borderRadius: '0.4rem',
                    color: 'white',
                    textAlign: 'center'
                  }}
                  onClick={() => {
                    startKYC('DRIVERLICENSE');
                    setDocumentType('driverlicense');
                  }}
                >
                  <ListItemText primary={t('Driver license')} />
                </ListItemButton>
              </ListItem>
            </List>
            <DialogActions>
              <Button onClick={handleCloseAliceDocument}>{t('Close')}</Button>
            </DialogActions>
          </DialogContent>
        </Dialog>
      </Paper>
    </React.Fragment>
  );
}
