import * as React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import { useTranslation } from 'gatsby-plugin-react-i18next';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import moment from 'moment';

export default function Payment(props) {
  const { t } = useTranslation();
  const { hotelName, bookingID, arrivalDate, departure, urlPago } = props;

  const { handleNext, handleBack } = props;

  const next = (e) => {
    e.preventDefault();
    let error = false;

    if (error) {
      return;
    }
    const newWindow = window.open(urlPago, '_blank', 'noopener,noreferrer');
    if (newWindow) {
      newWindow.opener = null;
    }
    handleNext();
  };

  const back = (e) => {
    e.preventDefault();
    handleBack();
  };

  return (
    <React.Fragment>
      <Typography variant='h6' gutterBottom>
        {t('Pay your booking')}
      </Typography>
      <Grid container>
        <Grid container direction={'row'}>
          <Grid
            container
            sx={{ display: 'flex', justifyContent: 'center' }}
            direction='row'
          >
            <Grid item xs={5} sm={4}>
              <Typography gutterBottom align='justify'>
                {t('Hotel')}:
              </Typography>
            </Grid>
            <Grid item xs={5} sm={4}>
              <Typography gutterBottom align='justify'>
                {hotelName}
              </Typography>
            </Grid>
          </Grid>

          <Grid
            container
            sx={{ display: 'flex', justifyContent: 'center' }}
            direction='row'
          >
            <Grid item xs={5} sm={4}>
              <Typography gutterBottom align='justify'>
                {t('Booking ID')}:
              </Typography>
            </Grid>
            <Grid item xs={5} sm={4}>
              <Typography gutterBottom align='justify'>
                {bookingID}
              </Typography>
            </Grid>
          </Grid>

          <Grid
            container
            sx={{ display: 'flex', justifyContent: 'center' }}
            direction='row'
          >
            <Grid item xs={5} sm={4}>
              <Typography gutterBottom align='justify'>
                {t('Arrival')}:
              </Typography>
            </Grid>
            <Grid item xs={5} sm={4}>
              <Typography gutterBottom align='justify'>
                {moment(arrivalDate).format('DD/MM/YYYY')}
              </Typography>
            </Grid>
          </Grid>

          <Grid
            container
            sx={{ display: 'flex', justifyContent: 'center' }}
            direction='row'
          >
            <Grid item xs={5} sm={4}>
              <Typography gutterBottom align='justify'>
                {t('Nights')}:
              </Typography>
            </Grid>
            <Grid item xs={5} sm={4}>
              <Typography gutterBottom align='justify'>
                {moment(departure).diff(moment(arrivalDate), 'days')}
              </Typography>
            </Grid>
          </Grid>

          <Grid
            container
            sx={{ display: 'flex', justifyContent: 'center' }}
            direction='row'
          >
            <Grid item xs={5} sm={4}>
              <Typography gutterBottom align='justify'>
                {t('Price')}:
              </Typography>
            </Grid>
            <Grid item xs={5} sm={4}>
              <Typography gutterBottom align='justify'>
                236,50 €
              </Typography>
            </Grid>
          </Grid>

          <Grid
            container
            sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}
            direction='row'
          >
            <Grid item xs={10} sm={10}>
              <Typography fontSize='0.8rem' gutterBottom align='justify'>
                {t(
                  'Clicking on pay will open a web page with the payment gateway to enter the payment details'
                )}
              </Typography>
            </Grid>
          </Grid>

          <Grid container spacing={1} direction='row' sx={{ mt: 1 }}>
            <Grid item xs={6} sm={6} style={{ margin: 'auto' }}>
              <React.Fragment>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                  <Button onClick={back}>{t('Back')}</Button>
                  <Button variant='contained' onClick={next} color='primary'>
                    {t('Pay')}
                  </Button>
                </Box>
              </React.Fragment>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
