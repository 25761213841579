const countries = [
  {
    alpha2: 'af',
    alpha3: 'afg',
    en: 'Afghanistan',
    phone: '93',
    de: 'Afghanistan',
    es: 'Afganistán',
    fr: 'Afghanistan',
    it: 'Afghanistan',
    ja: 'アフガニスタン',
    nl: 'Afghanistan',
    pt: 'Afeganistão',
    ru: 'Афганистан',
    sv: 'Afghanistan',
    zh: '阿富汗',
  },
  {
    alpha2: 'ax',
    alpha3: 'ala',
    en: 'Åland Islands',
    de: 'Åland',
    es: 'Åland',
    fr: 'Îles Åland',
    it: 'Isole Åland',
    ja: 'オーランド諸島',
    nl: 'Åland',
    pt: 'Ilhas Åland',
    ru: 'Аландские острова',
    sv: 'Åland',
    zh: '奥兰',
  },
  {
    alpha2: 'al',
    alpha3: 'alb',
    en: 'Albania',
    phone: '355',
    de: 'Albanien',
    es: 'Albania',
    fr: 'Albanie',
    it: 'Albania',
    ja: 'アルバニア',
    nl: 'Albanië',
    pt: 'Albânia',
    ru: 'Албания',
    sv: 'Albanien',
    zh: '阿尔巴尼亚',
  },
  {
    alpha2: 'dz',
    alpha3: 'dza',
    en: 'Algeria',
    phone: '213',
    de: 'Algerien',
    es: 'Argelia',
    fr: 'Algérie',
    it: 'Algeria',
    ja: 'アルジェリア',
    nl: 'Algerije',
    pt: 'Argélia',
    ru: 'Алжир',
    sv: 'Algeriet',
    zh: '阿尔及利亚',
  },
  {
    alpha2: 'as',
    alpha3: 'asm',
    en: 'American Samoa',
    phone: '1-684',
    de: 'Amerikanisch-Samoa',
    es: 'Samoa Americana',
    fr: 'Samoa américaines',
    it: 'Samoa Americane',
    ja: 'アメリカ領サモア',
    nl: 'Amerikaans-Samoa',
    pt: 'Samoa Americana',
    ru: 'Американское Самоа',
    sv: 'Amerikanska Samoa',
    zh: '美属萨摩亚',
  },
  {
    alpha2: 'ad',
    alpha3: 'and',
    en: 'Andorra',
    phone: '376',
    de: 'Andorra',
    es: 'Andorra',
    fr: 'Andorre',
    it: 'Andorra',
    ja: 'アンドラ',
    nl: 'Andorra',
    pt: 'Andorra',
    ru: 'Андорра',
    sv: 'Andorra',
    zh: '安道尔',
  },
  {
    alpha2: 'ao',
    alpha3: 'ago',
    en: 'Angola',
    phone: '244',
    de: 'Angola',
    es: 'Angola',
    fr: 'Angola',
    it: 'Angola',
    ja: 'アンゴラ',
    nl: 'Angola',
    pt: 'Angola',
    ru: 'Ангола',
    sv: 'Angola',
    zh: '安哥拉',
  },
  {
    alpha2: 'ai',
    alpha3: 'aia',
    en: 'Anguilla',
    phone: '1-264',
    de: 'Anguilla',
    es: 'Anguila',
    fr: 'Anguilla',
    it: 'Anguilla',
    ja: 'アンギラ',
    nl: 'Anguilla',
    pt: 'Anguila',
    ru: 'Ангилья',
    sv: 'Anguilla',
    zh: '安圭拉',
  },
  {
    alpha2: 'aq',
    alpha3: 'ata',
    en: 'Antarctica',
    phone: '672',
    de: 'Antarktis (Sonderstatus durch Antarktisvertrag)',
    es: 'Antártida',
    fr: 'Antarctique',
    it: 'Antartide',
    ja: '南極',
    nl: 'Antarctica',
    pt: 'Antártida',
    ru: 'Антарктика',
    sv: 'Antarktis',
    zh: '南极洲',
  },
  {
    alpha2: 'ag',
    alpha3: 'atg',
    en: 'Antigua and Barbuda',
    phone: '1-268',
    de: 'Antigua und Barbuda',
    es: 'Antigua y Barbuda',
    fr: 'Antigua-et-Barbuda',
    it: 'Antigua e Barbuda',
    ja: 'アンティグア・バーブーダ',
    nl: 'Antigua en Barbuda',
    pt: 'Antígua e Barbuda',
    ru: 'Антигуа и Барбуда',
    sv: 'Antigua och Barbuda',
    zh: '安提瓜和巴布达',
  },
  {
    alpha2: 'ar',
    alpha3: 'arg',
    en: 'Argentina',
    phone: '54',
    de: 'Argentinien',
    es: 'Argentina',
    fr: 'Argentine',
    it: 'Argentina',
    ja: 'アルゼンチン',
    nl: 'Argentinië',
    pt: 'Argentina',
    ru: 'Аргентина',
    sv: 'Argentina',
    zh: '阿根廷',
  },
  {
    alpha2: 'am',
    alpha3: 'arm',
    en: 'Armenia',
    phone: '374',
    de: 'Armenien',
    es: 'Armenia',
    fr: 'Arménie',
    it: 'Armenia',
    ja: 'アルメニア',
    nl: 'Armenië',
    pt: 'Armênia',
    ru: 'Армения',
    sv: 'Armenien',
    zh: '亚美尼亚',
  },
  {
    alpha2: 'aw',
    alpha3: 'abw',
    en: 'Aruba',
    phone: '297',
    de: 'Aruba',
    es: 'Aruba',
    fr: 'Aruba',
    it: 'Aruba',
    ja: 'アルバ',
    nl: 'Aruba',
    pt: 'Aruba',
    ru: 'Аруба',
    sv: 'Aruba',
    zh: '阿鲁巴',
  },
  {
    alpha2: 'au',
    alpha3: 'aus',
    en: 'Australia',
    phone: '61',
    de: 'Australien',
    es: 'Australia',
    fr: 'Australie',
    it: 'Australia',
    ja: 'オーストラリア',
    nl: 'Australië',
    pt: 'Austrália',
    ru: 'Австралия',
    sv: 'Australien',
    zh: '澳大利亚',
  },
  {
    alpha2: 'at',
    alpha3: 'aut',
    en: 'Austria',
    phone: '43',
    de: 'Österreich',
    es: 'Austria',
    fr: 'Autriche',
    it: 'Austria',
    ja: 'オーストリア',
    nl: 'Oostenrijk',
    pt: 'Áustria',
    ru: 'Австрия',
    sv: 'Österrike',
    zh: '奥地利',
  },
  {
    alpha2: 'az',
    alpha3: 'aze',
    en: 'Azerbaijan',
    phone: '994',
    de: 'Aserbaidschan',
    es: 'Azerbaiyán',
    fr: 'Azerbaïdjan',
    it: 'Azerbaigian',
    ja: 'アゼルバイジャン',
    nl: 'Azerbeidzjan',
    pt: 'Azerbaijão',
    ru: 'Азербайджан',
    sv: 'Azerbajdzjan',
    zh: '阿塞拜疆',
  },
  {
    alpha2: 'bs',
    alpha3: 'bhs',
    en: 'Bahamas',
    phone: '1-242',
    de: 'Bahamas',
    es: 'Bahamas',
    fr: 'Bahamas',
    it: 'Bahamas',
    ja: 'バハマ',
    nl: "Bahama's",
    pt: 'Bahamas',
    ru: 'Багамские Острова',
    sv: 'Bahamas',
    zh: '巴哈马',
  },
  {
    alpha2: 'bh',
    alpha3: 'bhr',
    en: 'Bahrain',
    phone: '973',
    de: 'Bahrain',
    es: 'Baréin',
    fr: 'Bahreïn',
    it: 'Bahrein',
    ja: 'バーレーン',
    nl: 'Bahrein',
    pt: 'Barém',
    ru: 'Бахрейн',
    sv: 'Bahrain',
    zh: '巴林',
  },
  {
    alpha2: 'bd',
    alpha3: 'bgd',
    en: 'Bangladesh',
    phone: '880',
    de: 'Bangladesch',
    es: 'Bangladés',
    fr: 'Bangladesh',
    it: 'Bangladesh',
    ja: 'バングラデシュ',
    nl: 'Bangladesh',
    pt: 'Bangladexe',
    ru: 'Бангладеш',
    sv: 'Bangladesh',
    zh: '孟加拉国',
  },
  {
    alpha2: 'bb',
    alpha3: 'brb',
    en: 'Barbados',
    phone: '1-246',
    de: 'Barbados',
    es: 'Barbados',
    fr: 'Barbade',
    it: 'Barbados',
    ja: 'バルバドス',
    nl: 'Barbados',
    pt: 'Barbados',
    ru: 'Барбадос',
    sv: 'Barbados',
    zh: '巴巴多斯',
  },
  {
    alpha2: 'by',
    alpha3: 'blr',
    en: 'Belarus',
    phone: '375',
    de: 'Belarus',
    es: 'Bielorrusia',
    fr: 'Biélorussie',
    it: 'Bielorussia',
    ja: 'ベラルーシ',
    nl: 'Wit-Rusland',
    pt: 'Bielorrússia',
    ru: 'Беларусь',
    sv: 'Belarus',
    zh: '白俄罗斯',
  },
  {
    alpha2: 'be',
    alpha3: 'bel',
    en: 'Belgium',
    phone: '32',
    de: 'Belgien',
    es: 'Bélgica',
    fr: 'Belgique',
    it: 'Belgio',
    ja: 'ベルギー',
    nl: 'België',
    pt: 'Bélgica',
    ru: 'Бельгия',
    sv: 'Belgien',
    zh: '比利时',
  },
  {
    alpha2: 'bz',
    alpha3: 'blz',
    en: 'Belize',
    phone: '501',
    de: 'Belize',
    es: 'Belice',
    fr: 'Belize',
    it: 'Belize',
    ja: 'ベリーズ',
    nl: 'Belize',
    pt: 'Belize',
    ru: 'Белиз',
    sv: 'Belize',
    zh: '伯利兹',
  },
  {
    alpha2: 'bj',
    alpha3: 'ben',
    en: 'Benin',
    phone: '229',
    de: 'Benin',
    es: 'Benín',
    fr: 'Bénin',
    it: 'Benin',
    ja: 'ベナン',
    nl: 'Benin',
    pt: 'Benim',
    ru: 'Бенин',
    sv: 'Benin',
    zh: '贝宁',
  },
  {
    alpha2: 'bm',
    alpha3: 'bmu',
    en: 'Bermuda',
    phone: '1-441',
    de: 'Bermuda',
    es: 'Bermudas',
    fr: 'Bermudes',
    it: 'Bermuda',
    ja: 'バミューダ',
    nl: 'Bermuda',
    pt: 'Bermudas',
    ru: 'Бермуды',
    sv: 'Bermuda',
    zh: '百慕大',
  },
  {
    alpha2: 'bt',
    alpha3: 'btn',
    en: 'Bhutan',
    phone: '975',
    de: 'Bhutan',
    es: 'Bután',
    fr: 'Bhoutan',
    it: 'Bhutan',
    ja: 'ブータン',
    nl: 'Bhutan',
    pt: 'Butão',
    ru: 'Бутан',
    sv: 'Bhutan',
    zh: '不丹',
  },
  {
    alpha2: 'bo',
    alpha3: 'bol',
    en: 'Bolivia (Plurinational State of)',
    de: 'Bolivien',
    es: 'Bolivia',
    fr: 'Bolivie',
    it: 'Bolivia',
    ja: 'ボリビア多民族国',
    nl: 'Bolivia',
    pt: 'Bolívia',
    ru: 'Боливия',
    sv: 'Bolivia',
    zh: '玻利维亚',
  },
  {
    alpha2: 'bq',
    alpha3: 'bes',
    en: 'Bonaire, Sint Eustatius and Saba',
    de: 'Bonaire, Saba, Sint Eustatius',
    es: 'Bonaire, San Eustaquio y Saba',
    fr: 'Pays-Bas caribéens',
    it: 'Isole BES',
    ja: 'ボネール、シント・ユースタティウスおよびサバ',
    nl: 'Caribisch Nederland',
    pt: 'Países Baixos Caribenhos',
    ru: 'Бонайре, Синт-Эстатиус и Саба',
    sv: 'Bonaire, Sint Eustatius and Saba',
    zh: '荷兰加勒比区',
  },
  {
    alpha2: 'ba',
    alpha3: 'bih',
    en: 'Bosnia and Herzegovina',
    phone: '387',
    de: 'Bosnien und Herzegowina',
    es: 'Bosnia y Herzegovina',
    fr: 'Bosnie-Herzégovine',
    it: 'Bosnia ed Erzegovina',
    ja: 'ボスニア・ヘルツェゴビナ',
    nl: 'Bosnië en Herzegovina',
    pt: 'Bósnia e Herzegovina',
    ru: 'Босния и Герцеговина',
    sv: 'Bosnien och Hercegovina',
    zh: '波黑',
  },
  {
    alpha2: 'bw',
    alpha3: 'bwa',
    en: 'Botswana',
    phone: '267',
    de: 'Botswana',
    es: 'Botsuana',
    fr: 'Botswana',
    it: 'Botswana',
    ja: 'ボツワナ',
    nl: 'Botswana',
    pt: 'Botsuana',
    ru: 'Ботсвана',
    sv: 'Botswana',
    zh: '博茨瓦纳',
  },
  {
    alpha2: 'bv',
    alpha3: 'bvt',
    en: 'Bouvet Island',
    phone: '47',
    de: 'Bouvetinsel',
    es: 'Isla Bouvet',
    fr: 'Île Bouvet',
    it: 'Isola Bouvet',
    ja: 'ブーベ島',
    nl: 'Bouvet',
    pt: 'Ilha Bouvet',
    ru: 'Остров Буве',
    sv: 'Bouvetön',
    zh: '布韦岛',
  },
  {
    alpha2: 'br',
    alpha3: 'bra',
    en: 'Brazil',
    phone: '55',
    de: 'Brasilien',
    es: 'Brasil',
    fr: 'Brésil',
    it: 'Brasile',
    ja: 'ブラジル',
    nl: 'Brazilië',
    pt: 'Brasil',
    ru: 'Бразилия',
    sv: 'Brasilien',
    zh: '巴西',
  },
  {
    alpha2: 'io',
    alpha3: 'iot',
    en: 'British Indian Ocean Territory',
    phone: '246',
    de: 'Britisches Territorium im Indischen Ozean',
    es: 'Territorio Británico del Océano Índico',
    fr: "Territoire britannique de l'océan Indien",
    it: "Territorio britannico dell'Oceano Indiano",
    ja: 'イギリス領インド洋地域',
    nl: 'Brits Indische Oceaanterritorium',
    pt: 'Território Britânico do Oceano Índico',
    ru: 'Британская Территория в Индийском Океане',
    sv: 'Brittiska territoriet i Indiska oceanen',
    zh: '英属印度洋领地',
  },
  {
    alpha2: 'bn',
    alpha3: 'brn',
    en: 'Brunei Darussalam',
    phone: '673',
    de: 'Brunei',
    es: 'Brunéi',
    fr: 'Brunei',
    it: 'Brunei',
    ja: 'ブルネイ・ダルサラーム',
    nl: 'Brunei',
    pt: 'Brunei',
    ru: 'Бруней',
    sv: 'Brunei',
    zh: '文莱',
  },
  {
    alpha2: 'bg',
    alpha3: 'bgr',
    en: 'Bulgaria',
    phone: '359',
    de: 'Bulgarien',
    es: 'Bulgaria',
    fr: 'Bulgarie',
    it: 'Bulgaria',
    ja: 'ブルガリア',
    nl: 'Bulgarije',
    pt: 'Bulgária',
    ru: 'Болгария',
    sv: 'Bulgarien',
    zh: '保加利亚',
  },
  {
    alpha2: 'bf',
    alpha3: 'bfa',
    en: 'Burkina Faso',
    phone: '226',
    de: 'Burkina Faso',
    es: 'Burkina Faso',
    fr: 'Burkina Faso',
    it: 'Burkina Faso',
    ja: 'ブルキナファソ',
    nl: 'Burkina Faso',
    pt: 'Burquina Fasso',
    ru: 'Буркина-Фасо',
    sv: 'Burkina Faso',
    zh: '布基纳法索',
  },
  {
    alpha2: 'bi',
    alpha3: 'bdi',
    en: 'Burundi',
    phone: '257',
    de: 'Burundi',
    es: 'Burundi',
    fr: 'Burundi',
    it: 'Burundi',
    ja: 'ブルンジ',
    nl: 'Burundi',
    pt: 'Burundi',
    ru: 'Бурунди',
    sv: 'Burundi',
    zh: '布隆迪',
  },
  {
    alpha2: 'kh',
    alpha3: 'khm',
    en: 'Cambodia',
    phone: '855',
    de: 'Kambodscha',
    es: 'Camboya',
    fr: 'Cambodge',
    it: 'Cambogia',
    ja: 'カンボジア',
    nl: 'Cambodja',
    pt: 'Camboja',
    ru: 'Камбоджа',
    sv: 'Kambodja',
    zh: '柬埔寨',
  },
  {
    alpha2: 'cm',
    alpha3: 'cmr',
    en: 'Cameroon',
    phone: '237',
    de: 'Kamerun',
    es: 'Camerún',
    fr: 'Cameroun',
    it: 'Camerun',
    ja: 'カメルーン',
    nl: 'Kameroen',
    pt: 'Camarões',
    ru: 'Камерун',
    sv: 'Kamerun',
    zh: '喀麦隆',
  },
  {
    alpha2: 'ca',
    alpha3: 'can',
    en: 'Canada',
    phone: '1',
    de: 'Kanada',
    es: 'Canadá',
    fr: 'Canada',
    it: 'Canada',
    ja: 'カナダ',
    nl: 'Canada',
    pt: 'Canadá',
    ru: 'Канада',
    sv: 'Kanada',
    zh: '加拿大',
  },
  {
    alpha2: 'cv',
    alpha3: 'cpv',
    en: 'Cabo Verde',
    phone: '238',
    de: 'Kap Verde',
    es: 'Cabo Verde',
    fr: 'Cap-Vert',
    it: 'Capo Verde',
    ja: 'カーボベルデ',
    nl: 'Kaapverdië',
    pt: 'Cabo Verde',
    ru: 'Кабо-Верде',
    sv: 'Kap Verde',
    zh: '佛得角',
  },
  {
    alpha2: 'ky',
    alpha3: 'cym',
    en: 'Cayman Islands',
    phone: '1-345',
    de: 'Kaimaninseln',
    es: 'Islas Caimán',
    fr: 'Îles Caïmans',
    it: 'Isole Cayman',
    ja: 'ケイマン諸島',
    nl: 'Kaaimaneilanden',
    pt: 'Ilhas Caimã',
    ru: 'Острова Кайман',
    sv: 'Caymanöarna',
    zh: '开曼群岛',
  },
  {
    alpha2: 'cf',
    alpha3: 'caf',
    en: 'Central African Republic',
    phone: '236',
    de: 'Zentralafrikanische Republik',
    es: 'República Centroafricana',
    fr: 'République centrafricaine',
    it: 'Rep. Centrafricana',
    ja: '中央アフリカ共和国',
    nl: 'Centraal-Afrikaanse Republiek',
    pt: 'República Centro-Africana',
    ru: 'ЦАР',
    sv: 'Centralafrikanska republiken',
    zh: '中非',
  },
  {
    alpha2: 'td',
    alpha3: 'tcd',
    en: 'Chad',
    phone: '235',
    de: 'Tschad',
    es: 'Chad',
    fr: 'Tchad',
    it: 'Ciad',
    ja: 'チャド',
    nl: 'Tsjaad',
    pt: 'Chade',
    ru: 'Чад',
    sv: 'Tchad',
    zh: '乍得',
  },
  {
    alpha2: 'cl',
    alpha3: 'chl',
    en: 'Chile',
    phone: '56',
    de: 'Chile',
    es: 'Chile',
    fr: 'Chili',
    it: 'Cile',
    ja: 'チリ',
    nl: 'Chili',
    pt: 'Chile',
    ru: 'Чили',
    sv: 'Chile',
    zh: '智利',
  },
  {
    alpha2: 'cn',
    alpha3: 'chn',
    en: 'China',
    phone: '86',
    de: 'China, Volksrepublik',
    es: 'China',
    fr: 'Chine',
    it: 'Cina',
    ja: '中華人民共和国',
    nl: 'China',
    pt: 'China',
    ru: 'Китай (Китайская Народная Республика)',
    sv: 'Kina',
    zh: '中国',
  },
  {
    alpha2: 'cx',
    alpha3: 'cxr',
    en: 'Christmas Island',
    phone: '61',
    de: 'Weihnachtsinsel',
    es: 'Isla de Navidad',
    fr: 'Île Christmas',
    it: 'Isola di Natale',
    ja: 'クリスマス島',
    nl: 'Christmaseiland',
    pt: 'Ilha do Natal',
    ru: 'Остров Рождества',
    sv: 'Julön',
    zh: '圣诞岛',
  },
  {
    alpha2: 'cc',
    alpha3: 'cck',
    en: 'Cocos (Keeling) Islands',
    phone: '61',
    de: 'Kokosinseln',
    es: 'Islas Cocos',
    fr: 'Îles Cocos',
    it: 'Isole Cocos (Keeling)',
    ja: 'ココス（キーリング）諸島',
    nl: 'Cocoseilanden',
    pt: 'Ilhas Cocos (Keeling)',
    ru: 'Кокосовые острова',
    sv: 'Kokosöarna',
    zh: '科科斯（基林）群岛',
  },
  {
    alpha2: 'co',
    alpha3: 'col',
    en: 'Colombia',
    phone: '57',
    de: 'Kolumbien',
    es: 'Colombia',
    fr: 'Colombie',
    it: 'Colombia',
    ja: 'コロンビア',
    nl: 'Colombia',
    pt: 'Colômbia',
    ru: 'Колумбия',
    sv: 'Colombia',
    zh: '哥伦比亚',
  },
  {
    alpha2: 'km',
    alpha3: 'com',
    en: 'Comoros',
    phone: '269',
    de: 'Komoren',
    es: 'Comoras',
    fr: 'Comores',
    it: 'Comore',
    ja: 'コモロ',
    nl: 'Comoren',
    pt: 'Comores',
    ru: 'Коморы',
    sv: 'Komorerna',
    zh: '科摩罗',
  },
  {
    alpha2: 'cg',
    alpha3: 'cog',
    en: 'Congo',
    de: 'Kongo, Republik',
    es: 'República del Congo',
    fr: 'République du Congo',
    it: 'Rep. del Congo',
    ja: 'コンゴ共和国',
    nl: 'Congo-Brazzaville',
    pt: 'República do Congo',
    ru: 'Республика Конго',
    sv: 'Kongo-Brazzaville',
    zh: '刚果共和国',
  },
  {
    alpha2: 'cd',
    alpha3: 'cod',
    en: 'Congo, Democratic Republic of the',
    de: 'Kongo, Demokratische Republik',
    es: 'República Democrática del Congo',
    fr: 'République démocratique du Congo',
    it: 'RD del Congo',
    ja: 'コンゴ民主共和国',
    nl: 'Congo-Kinshasa',
    pt: 'República Democrática do Congo',
    ru: 'ДР Конго',
    sv: 'Kongo-Kinshasa',
    zh: '刚果民主共和国',
  },
  {
    alpha2: 'ck',
    alpha3: 'cok',
    en: 'Cook Islands',
    phone: '682',
    de: 'Cookinseln',
    es: 'Islas Cook',
    fr: 'Îles Cook',
    it: 'Isole Cook',
    ja: 'クック諸島',
    nl: 'Cookeilanden',
    pt: 'Ilhas Cook',
    ru: 'Острова Кука',
    sv: 'Cooköarna',
    zh: '库克群岛',
  },
  {
    alpha2: 'cr',
    alpha3: 'cri',
    en: 'Costa Rica',
    phone: '506',
    de: 'Costa Rica',
    es: 'Costa Rica',
    fr: 'Costa Rica',
    it: 'Costa Rica',
    ja: 'コスタリカ',
    nl: 'Costa Rica',
    pt: 'Costa Rica',
    ru: 'Коста-Рика',
    sv: 'Costa Rica',
    zh: '哥斯达黎加',
  },
  {
    alpha2: 'ci',
    alpha3: 'civ',
    en: "Côte d'Ivoire",
    de: 'Elfenbeinküste',
    es: 'Costa de Marfil',
    fr: "Côte d'Ivoire",
    it: "Costa d'Avorio",
    ja: 'コートジボワール',
    nl: 'Ivoorkust',
    pt: 'Costa do Marfim',
    ru: 'Кот-д’Ивуар',
    sv: 'Elfenbenskusten',
    zh: '科特迪瓦',
  },
  {
    alpha2: 'hr',
    alpha3: 'hrv',
    en: 'Croatia',
    phone: '385',
    de: 'Kroatien',
    es: 'Croacia',
    fr: 'Croatie',
    it: 'Croazia',
    ja: 'クロアチア',
    nl: 'Kroatië',
    pt: 'Croácia',
    ru: 'Хорватия',
    sv: 'Kroatien',
    zh: '克罗地亚',
  },
  {
    alpha2: 'cu',
    alpha3: 'cub',
    en: 'Cuba',
    phone: '53',
    de: 'Kuba',
    es: 'Cuba',
    fr: 'Cuba',
    it: 'Cuba',
    ja: 'キューバ',
    nl: 'Cuba',
    pt: 'Cuba',
    ru: 'Куба',
    sv: 'Kuba',
    zh: '古巴',
  },
  {
    alpha2: 'cw',
    alpha3: 'cuw',
    en: 'Curaçao',
    de: 'Curaçao',
    es: 'Curazao',
    fr: 'Curaçao',
    it: 'Curaçao',
    ja: 'キュラソー',
    nl: 'Curaçao',
    pt: 'Curaçau',
    ru: 'Кюрасао',
    sv: 'Curaçao',
    zh: '库拉索',
  },
  {
    alpha2: 'cy',
    alpha3: 'cyp',
    en: 'Cyprus',
    phone: '357',
    de: 'Zypern',
    es: 'Chipre',
    fr: 'Chypre',
    it: 'Cipro',
    ja: 'キプロス',
    nl: 'Cyprus',
    pt: 'Chipre',
    ru: 'Кипр',
    sv: 'Cypern',
    zh: '塞浦路斯',
  },
  {
    alpha2: 'cz',
    alpha3: 'cze',
    en: 'Czechia',
    phone: '420',
    de: 'Tschechien',
    es: 'República Checa',
    fr: 'Tchéquie',
    it: 'Rep. Ceca',
    ja: 'チェコ',
    nl: 'Tsjechië',
    pt: 'Chéquia',
    ru: 'Чехия',
    sv: 'Tjeckien',
    zh: '捷克',
  },
  {
    alpha2: 'dk',
    alpha3: 'dnk',
    en: 'Denmark',
    phone: '45',
    de: 'Dänemark',
    es: 'Dinamarca',
    fr: 'Danemark',
    it: 'Danimarca',
    ja: 'デンマーク',
    nl: 'Denemarken',
    pt: 'Dinamarca',
    ru: 'Дания',
    sv: 'Danmark',
    zh: '丹麦',
  },
  {
    alpha2: 'dj',
    alpha3: 'dji',
    en: 'Djibouti',
    phone: '253',
    de: 'Dschibuti',
    es: 'Yibuti',
    fr: 'Djibouti',
    it: 'Gibuti',
    ja: 'ジブチ',
    nl: 'Djibouti',
    pt: 'Djibuti',
    ru: 'Джибути',
    sv: 'Djibouti',
    zh: '吉布提',
  },
  {
    alpha2: 'dm',
    alpha3: 'dma',
    en: 'Dominica',
    phone: '1-767',
    de: 'Dominica',
    es: 'Dominica',
    fr: 'Dominique',
    it: 'Dominica',
    ja: 'ドミニカ国',
    nl: 'Dominica',
    pt: 'Dominica',
    ru: 'Доминика',
    sv: 'Dominica',
    zh: '多米尼克',
  },
  {
    alpha2: 'do',
    alpha3: 'dom',
    en: 'Dominican Republic',
    phone: '1-809',
    de: 'Dominikanische Republik',
    es: 'República Dominicana',
    fr: 'République dominicaine',
    it: 'Rep. Dominicana',
    ja: 'ドミニカ共和国',
    nl: 'Dominicaanse Republiek',
    pt: 'República Dominicana',
    ru: 'Доминиканская Республика',
    sv: 'Dominikanska republiken',
    zh: '多米尼加',
  },
  {
    alpha2: 'ec',
    alpha3: 'ecu',
    en: 'Ecuador',
    phone: '593',
    de: 'Ecuador',
    es: 'Ecuador',
    fr: 'Équateur',
    it: 'Ecuador',
    ja: 'エクアドル',
    nl: 'Ecuador',
    pt: 'Equador',
    ru: 'Эквадор',
    sv: 'Ecuador',
    zh: '厄瓜多尔',
  },
  {
    alpha2: 'eg',
    alpha3: 'egy',
    en: 'Egypt',
    phone: '20',
    de: 'Ägypten',
    es: 'Egipto',
    fr: 'Égypte',
    it: 'Egitto',
    ja: 'エジプト',
    nl: 'Egypte',
    pt: 'Egito',
    ru: 'Египет',
    sv: 'Egypten',
    zh: '埃及',
  },
  {
    alpha2: 'sv',
    alpha3: 'slv',
    en: 'El Salvador',
    phone: '503',
    de: 'El Salvador',
    es: 'El Salvador',
    fr: 'Salvador',
    it: 'El Salvador',
    ja: 'エルサルバドル',
    nl: 'El Salvador',
    pt: 'El Salvador',
    ru: 'Сальвадор',
    sv: 'El Salvador',
    zh: '萨尔瓦多',
  },
  {
    alpha2: 'gq',
    alpha3: 'gnq',
    en: 'Equatorial Guinea',
    phone: '240',
    de: 'Äquatorialguinea',
    es: 'Guinea Ecuatorial',
    fr: 'Guinée équatoriale',
    it: 'Guinea Equatoriale',
    ja: '赤道ギニア',
    nl: 'Equatoriaal-Guinea',
    pt: 'Guiné Equatorial',
    ru: 'Экваториальная Гвинея',
    sv: 'Ekvatorialguinea',
    zh: '赤道几内亚',
  },
  {
    alpha2: 'er',
    alpha3: 'eri',
    en: 'Eritrea',
    phone: '291',
    de: 'Eritrea',
    es: 'Eritrea',
    fr: 'Érythrée',
    it: 'Eritrea',
    ja: 'エリトリア',
    nl: 'Eritrea',
    pt: 'Eritreia',
    ru: 'Эритрея',
    sv: 'Eritrea',
    zh: '厄立特里亚',
  },
  {
    alpha2: 'ee',
    alpha3: 'est',
    en: 'Estonia',
    phone: '372',
    de: 'Estland',
    es: 'Estonia',
    fr: 'Estonie',
    it: 'Estonia',
    ja: 'エストニア',
    nl: 'Estland',
    pt: 'Estónia',
    ru: 'Эстония',
    sv: 'Estland',
    zh: '爱沙尼亚',
  },
  {
    alpha2: 'et',
    alpha3: 'eth',
    en: 'Ethiopia',
    phone: '251',
    de: 'Äthiopien',
    es: 'Etiopía',
    fr: 'Éthiopie',
    it: 'Etiopia',
    ja: 'エチオピア',
    nl: 'Ethiopië',
    pt: 'Etiópia',
    ru: 'Эфиопия',
    sv: 'Etiopien',
    zh: '埃塞俄比亚',
  },
  {
    alpha2: 'fk',
    alpha3: 'flk',
    en: 'Falkland Islands (Malvinas)',
    phone: '500',
    de: 'Falklandinseln',
    es: 'Islas Malvinas',
    fr: 'Malouines',
    it: 'Isole Falkland',
    ja: 'フォークランド（マルビナス）諸島',
    nl: 'Falklandeilanden',
    pt: 'Ilhas Malvinas',
    ru: 'Фолклендские острова',
    sv: 'Falklandsöarna',
    zh: '福克兰群岛',
  },
  {
    alpha2: 'fo',
    alpha3: 'fro',
    en: 'Faroe Islands',
    phone: '298',
    de: 'Färöer',
    es: 'Islas Feroe',
    fr: 'Îles Féroé',
    it: 'Fær Øer',
    ja: 'フェロー諸島',
    nl: 'Faeröer',
    pt: 'Ilhas Feroé',
    ru: 'Фарерские острова',
    sv: 'Färöarna',
    zh: '法罗群岛',
  },
  {
    alpha2: 'fj',
    alpha3: 'fji',
    en: 'Fiji',
    phone: '679',
    de: 'Fidschi',
    es: 'Fiyi',
    fr: 'Fidji',
    it: 'Figi',
    ja: 'フィジー',
    nl: 'Fiji',
    pt: 'Fiji',
    ru: 'Фиджи',
    sv: 'Fiji',
    zh: '斐济',
  },
  {
    alpha2: 'fi',
    alpha3: 'fin',
    en: 'Finland',
    phone: '358',
    de: 'Finnland',
    es: 'Finlandia',
    fr: 'Finlande',
    it: 'Finlandia',
    ja: 'フィンランド',
    nl: 'Finland',
    pt: 'Finlândia',
    ru: 'Финляндия',
    sv: 'Finland',
    zh: '芬兰',
  },
  {
    alpha2: 'fr',
    alpha3: 'fra',
    en: 'France',
    phone: '33',
    de: 'Frankreich',
    es: 'Francia',
    fr: 'France',
    it: 'Francia',
    ja: 'フランス',
    nl: 'Frankrijk',
    pt: 'França',
    ru: 'Франция',
    sv: 'Frankrike',
    zh: '法国',
  },
  {
    alpha2: 'gf',
    alpha3: 'guf',
    en: 'French Guiana',
    phone: '594',
    de: 'Französisch-Guayana',
    es: 'Guayana Francesa',
    fr: 'Guyane',
    it: 'Guyana francese',
    ja: 'フランス領ギアナ',
    nl: 'Frans-Guyana',
    pt: 'Guiana Francesa',
    ru: 'Гвиана',
    sv: 'Franska Guyana',
    zh: '法属圭亚那',
  },
  {
    alpha2: 'pf',
    alpha3: 'pyf',
    en: 'French Polynesia',
    phone: '689',
    de: 'Französisch-Polynesien',
    es: 'Polinesia Francesa',
    fr: 'Polynésie française',
    it: 'Polinesia francese',
    ja: 'フランス領ポリネシア',
    nl: 'Frans-Polynesië',
    pt: 'Polinésia Francesa',
    ru: 'Французская Полинезия',
    sv: 'Franska Polynesien',
    zh: '法属波利尼西亚',
  },
  {
    alpha2: 'tf',
    alpha3: 'atf',
    en: 'French Southern Territories',
    phone: '262',
    de: 'Französische Süd- und Antarktisgebiete',
    es: 'Tierras Australes y Antárticas Francesas',
    fr: 'Terres australes et antarctiques françaises',
    it: 'Terre australi e antartiche francesi',
    ja: 'フランス領南方・南極地域',
    nl: 'Franse Zuidelijke Gebieden',
    pt: 'Terras Austrais e Antárticas Francesas',
    ru: 'Французские Южные и Антарктические территории',
    sv: 'Franska sydterritorierna',
    zh: '法属南部和南极领地',
  },
  {
    alpha2: 'ga',
    alpha3: 'gab',
    en: 'Gabon',
    phone: '241',
    de: 'Gabun',
    es: 'Gabón',
    fr: 'Gabon',
    it: 'Gabon',
    ja: 'ガボン',
    nl: 'Gabon',
    pt: 'Gabão',
    ru: 'Габон',
    sv: 'Gabon',
    zh: '加蓬',
  },
  {
    alpha2: 'gm',
    alpha3: 'gmb',
    en: 'Gambia',
    phone: '220',
    de: 'Gambia',
    es: 'Gambia',
    fr: 'Gambie',
    it: 'Gambia',
    ja: 'ガンビア',
    nl: 'Gambia',
    pt: 'Gâmbia',
    ru: 'Гамбия',
    sv: 'Gambia',
    zh: '冈比亚',
  },
  {
    alpha2: 'ge',
    alpha3: 'geo',
    en: 'Georgia',
    phone: '995',
    de: 'Georgien',
    es: 'Georgia',
    fr: 'Géorgie',
    it: 'Georgia',
    ja: 'ジョージア',
    nl: 'Georgië',
    pt: 'Geórgia',
    ru: 'Грузия',
    sv: 'Georgien',
    zh: '格鲁吉亚',
  },
  {
    alpha2: 'de',
    alpha3: 'deu',
    en: 'Germany',
    phone: '49',
    de: 'Deutschland',
    es: 'Alemania',
    fr: 'Allemagne',
    it: 'Germania',
    ja: 'ドイツ',
    nl: 'Duitsland',
    pt: 'Alemanha',
    ru: 'Германия',
    sv: 'Tyskland',
    zh: '德国',
  },
  {
    alpha2: 'gh',
    alpha3: 'gha',
    en: 'Ghana',
    phone: '233',
    de: 'Ghana',
    es: 'Ghana',
    fr: 'Ghana',
    it: 'Ghana',
    ja: 'ガーナ',
    nl: 'Ghana',
    pt: 'Gana',
    ru: 'Гана',
    sv: 'Ghana',
    zh: '加纳',
  },
  {
    alpha2: 'gi',
    alpha3: 'gib',
    en: 'Gibraltar',
    phone: '350',
    de: 'Gibraltar',
    es: 'Gibraltar',
    fr: 'Gibraltar',
    it: 'Gibilterra',
    ja: 'ジブラルタル',
    nl: 'Gibraltar',
    pt: 'Gibraltar',
    ru: 'Гибралтар',
    sv: 'Gibraltar',
    zh: '直布罗陀',
  },
  {
    alpha2: 'gr',
    alpha3: 'grc',
    en: 'Greece',
    phone: '30',
    de: 'Griechenland',
    es: 'Grecia',
    fr: 'Grèce',
    it: 'Grecia',
    ja: 'ギリシャ',
    nl: 'Griekenland',
    pt: 'Grécia',
    ru: 'Греция',
    sv: 'Grekland',
    zh: '希腊',
  },
  {
    alpha2: 'gl',
    alpha3: 'grl',
    en: 'Greenland',
    phone: '299',
    de: 'Grönland',
    es: 'Groenlandia',
    fr: 'Groenland',
    it: 'Groenlandia',
    ja: 'グリーンランド',
    nl: 'Groenland',
    pt: 'Gronelândia',
    ru: 'Гренландия',
    sv: 'Grönland',
    zh: '格陵兰',
  },
  {
    alpha2: 'gd',
    alpha3: 'grd',
    en: 'Grenada',
    phone: '1-473',
    de: 'Grenada',
    es: 'Granada',
    fr: 'Grenade',
    it: 'Grenada',
    ja: 'グレナダ',
    nl: 'Grenada',
    pt: 'Granada',
    ru: 'Гренада',
    sv: 'Grenada',
    zh: '格林纳达',
  },
  {
    alpha2: 'gp',
    alpha3: 'glp',
    en: 'Guadeloupe',
    phone: '590',
    de: 'Guadeloupe',
    es: 'Guadalupe',
    fr: 'Guadeloupe',
    it: 'Guadalupa',
    ja: 'グアドループ',
    nl: 'Guadeloupe',
    pt: 'Guadalupe',
    ru: 'Гваделупа',
    sv: 'Guadeloupe',
    zh: '瓜德罗普',
  },
  {
    alpha2: 'gu',
    alpha3: 'gum',
    en: 'Guam',
    phone: '1-671',
    de: 'Guam',
    es: 'Guam',
    fr: 'Guam',
    it: 'Guam',
    ja: 'グアム',
    nl: 'Guam',
    pt: 'Guam',
    ru: 'Гуам',
    sv: 'Guam',
    zh: '关岛',
  },
  {
    alpha2: 'gt',
    alpha3: 'gtm',
    en: 'Guatemala',
    phone: '502',
    de: 'Guatemala',
    es: 'Guatemala',
    fr: 'Guatemala',
    it: 'Guatemala',
    ja: 'グアテマラ',
    nl: 'Guatemala',
    pt: 'Guatemala',
    ru: 'Гватемала',
    sv: 'Guatemala',
    zh: '危地马拉',
  },
  {
    alpha2: 'gg',
    alpha3: 'ggy',
    en: 'Guernsey',
    phone: '44',
    de: 'Guernsey (Kanalinsel)',
    es: 'Guernsey',
    fr: 'Guernesey',
    it: 'Guernsey',
    ja: 'ガーンジー',
    nl: 'Guernsey',
    pt: 'Guernsey',
    ru: 'Гернси',
    sv: 'Guernsey',
    zh: '根西',
  },
  {
    alpha2: 'gn',
    alpha3: 'gin',
    en: 'Guinea',
    phone: '224',
    de: 'Guinea',
    es: 'Guinea',
    fr: 'Guinée',
    it: 'Guinea',
    ja: 'ギニア',
    nl: 'Guinee',
    pt: 'Guiné',
    ru: 'Гвинея',
    sv: 'Guinea',
    zh: '几内亚',
  },
  {
    alpha2: 'gw',
    alpha3: 'gnb',
    en: 'Guinea-Bissau',
    phone: '245',
    de: 'Guinea-Bissau',
    es: 'Guinea-Bisáu',
    fr: 'Guinée-Bissau',
    it: 'Guinea-Bissau',
    ja: 'ギニアビサウ',
    nl: 'Guinee-Bissau',
    pt: 'Guiné-Bissau',
    ru: 'Гвинея-Бисау',
    sv: 'Guinea-Bissau',
    zh: '几内亚比绍',
  },
  {
    alpha2: 'gy',
    alpha3: 'guy',
    en: 'Guyana',
    phone: '592',
    de: 'Guyana',
    es: 'Guyana',
    fr: 'Guyana',
    it: 'Guyana',
    ja: 'ガイアナ',
    nl: 'Guyana',
    pt: 'Guiana',
    ru: 'Гайана',
    sv: 'Guyana',
    zh: '圭亚那',
  },
  {
    alpha2: 'ht',
    alpha3: 'hti',
    en: 'Haiti',
    phone: '509',
    de: 'Haiti',
    es: 'Haití',
    fr: 'Haïti',
    it: 'Haiti',
    ja: 'ハイチ',
    nl: 'Haïti',
    pt: 'Haiti',
    ru: 'Гаити',
    sv: 'Haiti',
    zh: '海地',
  },
  {
    alpha2: 'hm',
    alpha3: 'hmd',
    en: 'Heard Island and McDonald Islands',
    phone: '672',
    de: 'Heard und McDonaldinseln',
    es: 'Islas Heard y McDonald',
    fr: 'Îles Heard-et-MacDonald',
    it: 'Isole Heard e McDonald',
    ja: 'ハード島とマクドナルド諸島',
    nl: 'Heard en McDonaldeilanden',
    pt: 'Ilha Heard e Ilhas McDonald',
    ru: 'Херд и Макдональд',
    sv: 'Heard- och McDonaldöarna',
    zh: '赫德岛和麦克唐纳群岛',
  },
  {
    alpha2: 'va',
    alpha3: 'vat',
    en: 'Holy See',
    phone: '379',
    de: 'Vatikanstadt',
    es: 'Vaticano, Ciudad del',
    fr: 'Saint-Siège (État de la Cité du Vatican)',
    it: 'Città del Vaticano',
    ja: 'バチカン市国',
    nl: 'Vaticaanstad',
    pt: 'Vaticano',
    ru: 'Ватикан',
    sv: 'Vatikanstaten',
    zh: '梵蒂冈',
  },
  {
    alpha2: 'hn',
    alpha3: 'hnd',
    en: 'Honduras',
    phone: '504',
    de: 'Honduras',
    es: 'Honduras',
    fr: 'Honduras',
    it: 'Honduras',
    ja: 'ホンジュラス',
    nl: 'Honduras',
    pt: 'Honduras',
    ru: 'Гондурас',
    sv: 'Honduras',
    zh: '洪都拉斯',
  },
  {
    alpha2: 'hk',
    alpha3: 'hkg',
    en: 'Hong Kong',
    phone: '852',
    de: 'Hongkong',
    es: 'Hong Kong',
    fr: 'Hong Kong',
    it: 'Hong Kong',
    ja: '香港',
    nl: 'Hongkong',
    pt: 'Hong Kong',
    ru: 'Гонконг',
    sv: 'Hongkong',
    zh: '香港',
  },
  {
    alpha2: 'hu',
    alpha3: 'hun',
    en: 'Hungary',
    phone: '36',
    de: 'Ungarn',
    es: 'Hungría',
    fr: 'Hongrie',
    it: 'Ungheria',
    ja: 'ハンガリー',
    nl: 'Hongarije',
    pt: 'Hungria',
    ru: 'Венгрия',
    sv: 'Ungern',
    zh: '匈牙利',
  },
  {
    alpha2: 'is',
    alpha3: 'isl',
    en: 'Iceland',
    phone: '354',
    de: 'Island',
    es: 'Islandia',
    fr: 'Islande',
    it: 'Islanda',
    ja: 'アイスランド',
    nl: 'IJsland',
    pt: 'Islândia',
    ru: 'Исландия',
    sv: 'Island',
    zh: '冰岛',
  },
  {
    alpha2: 'in',
    alpha3: 'ind',
    en: 'India',
    phone: '91',
    de: 'Indien',
    es: 'India',
    fr: 'Inde',
    it: 'India',
    ja: 'インド',
    nl: 'India',
    pt: 'Índia',
    ru: 'Индия',
    sv: 'Indien',
    zh: '印度',
  },
  {
    alpha2: 'id',
    alpha3: 'idn',
    en: 'Indonesia',
    phone: '62',
    de: 'Indonesien',
    es: 'Indonesia',
    fr: 'Indonésie',
    it: 'Indonesia',
    ja: 'インドネシア',
    nl: 'Indonesië',
    pt: 'Indonésia',
    ru: 'Индонезия',
    sv: 'Indonesien',
    zh: '印度尼西亚',
  },
  {
    alpha2: 'ir',
    alpha3: 'irn',
    en: 'Iran (Islamic Republic of)',
    phone: '98',
    de: 'Iran',
    es: 'Irán',
    fr: 'Iran',
    it: 'Iran',
    ja: 'イラン・イスラム共和国',
    nl: 'Iran',
    pt: 'Irã',
    ru: 'Иран',
    sv: 'Iran',
    zh: '伊朗',
  },
  {
    alpha2: 'iq',
    alpha3: 'irq',
    en: 'Iraq',
    phone: '964',
    de: 'Irak',
    es: 'Irak',
    fr: 'Irak',
    it: 'Iraq',
    ja: 'イラク',
    nl: 'Irak',
    pt: 'Iraque',
    ru: 'Ирак',
    sv: 'Irak',
    zh: '伊拉克',
  },
  {
    alpha2: 'ie',
    alpha3: 'irl',
    en: 'Ireland',
    phone: '353',
    de: 'Irland',
    es: 'Irlanda',
    fr: 'Irlande',
    it: 'Irlanda',
    ja: 'アイルランド',
    nl: 'Ierland',
    pt: 'Irlanda',
    ru: 'Ирландия',
    sv: 'Irland',
    zh: '爱尔兰',
  },
  {
    alpha2: 'im',
    alpha3: 'imn',
    en: 'Isle of Man',
    phone: '44',
    de: 'Insel Man',
    es: 'Isla de Man',
    fr: 'Île de Man',
    it: 'Isola di Man',
    ja: 'マン島',
    nl: 'Man',
    pt: 'Ilha de Man',
    ru: 'Остров Мэн',
    sv: 'Isle of Man',
    zh: '马恩岛',
  },
  {
    alpha2: 'il',
    alpha3: 'isr',
    en: 'Israel',
    phone: '972',
    de: 'Israel',
    es: 'Israel',
    fr: 'Israël',
    it: 'Israele',
    ja: 'イスラエル',
    nl: 'Israël',
    pt: 'Israel',
    ru: 'Израиль',
    sv: 'Israel',
    zh: '以色列',
  },
  {
    alpha2: 'it',
    alpha3: 'ita',
    en: 'Italy',
    phone: '39',
    de: 'Italien',
    es: 'Italia',
    fr: 'Italie',
    it: 'Italia',
    ja: 'イタリア',
    nl: 'Italië',
    pt: 'Itália',
    ru: 'Италия',
    sv: 'Italien',
    zh: '意大利',
  },
  {
    alpha2: 'jm',
    alpha3: 'jam',
    en: 'Jamaica',
    phone: '1-876',
    de: 'Jamaika',
    es: 'Jamaica',
    fr: 'Jamaïque',
    it: 'Giamaica',
    ja: 'ジャマイカ',
    nl: 'Jamaica',
    pt: 'Jamaica',
    ru: 'Ямайка',
    sv: 'Jamaica',
    zh: '牙买加',
  },
  {
    alpha2: 'jp',
    alpha3: 'jpn',
    en: 'Japan',
    phone: '81',
    de: 'Japan',
    es: 'Japón',
    fr: 'Japon',
    it: 'Giappone',
    ja: '日本',
    nl: 'Japan',
    pt: 'Japão',
    ru: 'Япония',
    sv: 'Japan',
    zh: '日本',
  },
  {
    alpha2: 'je',
    alpha3: 'jey',
    en: 'Jersey',
    phone: '44',
    de: 'Jersey (Kanalinsel)',
    es: 'Jersey',
    fr: 'Jersey',
    it: 'Jersey',
    ja: 'ジャージー',
    nl: 'Jersey',
    pt: 'Jersey',
    ru: 'Джерси',
    sv: 'Jersey',
    zh: '泽西',
  },
  {
    alpha2: 'jo',
    alpha3: 'jor',
    en: 'Jordan',
    phone: '962',
    de: 'Jordanien',
    es: 'Jordania',
    fr: 'Jordanie',
    it: 'Giordania',
    ja: 'ヨルダン',
    nl: 'Jordanië',
    pt: 'Jordânia',
    ru: 'Иордания',
    sv: 'Jordanien',
    zh: '约旦',
  },
  {
    alpha2: 'kz',
    alpha3: 'kaz',
    en: 'Kazakhstan',
    phone: '7',
    de: 'Kasachstan',
    es: 'Kazajistán',
    fr: 'Kazakhstan',
    it: 'Kazakistan',
    ja: 'カザフスタン',
    nl: 'Kazachstan',
    pt: 'Cazaquistão',
    ru: 'Казахстан',
    sv: 'Kazakstan',
    zh: '哈萨克斯坦',
  },
  {
    alpha2: 'ke',
    alpha3: 'ken',
    en: 'Kenya',
    phone: '254',
    de: 'Kenia',
    es: 'Kenia',
    fr: 'Kenya',
    it: 'Kenya',
    ja: 'ケニア',
    nl: 'Kenia',
    pt: 'Quênia',
    ru: 'Кения',
    sv: 'Kenya',
    zh: '肯尼亚',
  },
  {
    alpha2: 'ki',
    alpha3: 'kir',
    en: 'Kiribati',
    phone: '686',
    de: 'Kiribati',
    es: 'Kiribati',
    fr: 'Kiribati',
    it: 'Kiribati',
    ja: 'キリバス',
    nl: 'Kiribati',
    pt: 'Quiribáti',
    ru: 'Кирибати',
    sv: 'Kiribati',
    zh: '基里巴斯',
  },
  {
    alpha2: 'kp',
    alpha3: 'prk',
    en: "Korea (Democratic People's Republic of)",
    phone: '850',
    de: 'Korea, Nord (Nordkorea)',
    es: 'Corea del Norte',
    fr: 'Corée du Nord',
    it: 'Corea del Nord',
    ja: '朝鮮民主主義人民共和国',
    nl: 'Noord-Korea',
    pt: 'Coreia do Norte',
    ru: 'КНДР (Корейская Народно-Демократическая Республика)',
    sv: 'Nordkorea',
    zh: '朝鲜',
  },
  {
    alpha2: 'kr',
    alpha3: 'kor',
    en: 'Korea, Republic of',
    phone: '82',
    de: 'Korea, Süd (Südkorea)',
    es: 'Corea del Sur',
    fr: 'Corée du Sud',
    it: 'Corea del Sud',
    ja: '大韓民国',
    nl: 'Zuid-Korea',
    pt: 'Coreia do Sul',
    ru: 'Республика Корея',
    sv: 'Sydkorea',
    zh: '韩国',
  },
  {
    alpha2: 'kw',
    alpha3: 'kwt',
    en: 'Kuwait',
    phone: '965',
    de: 'Kuwait',
    es: 'Kuwait',
    fr: 'Koweït',
    it: 'Kuwait',
    ja: 'クウェート',
    nl: 'Koeweit',
    pt: 'Kuwait',
    ru: 'Кувейт',
    sv: 'Kuwait',
    zh: '科威特',
  },
  {
    alpha2: 'kg',
    alpha3: 'kgz',
    en: 'Kyrgyzstan',
    phone: '996',
    de: 'Kirgisistan',
    es: 'Kirguistán',
    fr: 'Kirghizistan',
    it: 'Kirghizistan',
    ja: 'キルギス',
    nl: 'Kirgizië',
    pt: 'Quirguistão',
    ru: 'Киргизия',
    sv: 'Kirgizistan',
    zh: '吉尔吉斯斯坦',
  },
  {
    alpha2: 'la',
    alpha3: 'lao',
    en: "Lao People's Democratic Republic",
    phone: '856',
    de: 'Laos',
    es: 'Laos',
    fr: 'Laos',
    it: 'Laos',
    ja: 'ラオス人民民主共和国',
    nl: 'Laos',
    pt: 'Laos',
    ru: 'Лаос',
    sv: 'Laos',
    zh: '老挝',
  },
  {
    alpha2: 'lv',
    alpha3: 'lva',
    en: 'Latvia',
    phone: '371',
    de: 'Lettland',
    es: 'Letonia',
    fr: 'Lettonie',
    it: 'Lettonia',
    ja: 'ラトビア',
    nl: 'Letland',
    pt: 'Letônia',
    ru: 'Латвия',
    sv: 'Lettland',
    zh: '拉脱维亚',
  },
  {
    alpha2: 'lb',
    alpha3: 'lbn',
    en: 'Lebanon',
    phone: '961',
    de: 'Libanon',
    es: 'Líbano',
    fr: 'Liban',
    it: 'Libano',
    ja: 'レバノン',
    nl: 'Libanon',
    pt: 'Líbano',
    ru: 'Ливан',
    sv: 'Libanon',
    zh: '黎巴嫩',
  },
  {
    alpha2: 'ls',
    alpha3: 'lso',
    en: 'Lesotho',
    phone: '266',
    de: 'Lesotho',
    es: 'Lesoto',
    fr: 'Lesotho',
    it: 'Lesotho',
    ja: 'レソト',
    nl: 'Lesotho',
    pt: 'Lesoto',
    ru: 'Лесото',
    sv: 'Lesotho',
    zh: '莱索托',
  },
  {
    alpha2: 'lr',
    alpha3: 'lbr',
    en: 'Liberia',
    phone: '231',
    de: 'Liberia',
    es: 'Liberia',
    fr: 'Liberia',
    it: 'Liberia',
    ja: 'リベリア',
    nl: 'Liberia',
    pt: 'Libéria',
    ru: 'Либерия',
    sv: 'Liberia',
    zh: '利比里亚',
  },
  {
    alpha2: 'ly',
    alpha3: 'lby',
    en: 'Libya',
    phone: '218',
    de: 'Libyen',
    es: 'Libia',
    fr: 'Libye',
    it: 'Libia',
    ja: 'リビア',
    nl: 'Libië',
    pt: 'Líbia',
    ru: 'Ливия',
    sv: 'Libyen',
    zh: '利比亚',
  },
  {
    alpha2: 'li',
    alpha3: 'lie',
    en: 'Liechtenstein',
    phone: '423',
    de: 'Liechtenstein',
    es: 'Liechtenstein',
    fr: 'Liechtenstein',
    it: 'Liechtenstein',
    ja: 'リヒテンシュタイン',
    nl: 'Liechtenstein',
    pt: 'Listenstaine',
    ru: 'Лихтенштейн',
    sv: 'Liechtenstein',
    zh: '列支敦士登',
  },
  {
    alpha2: 'lt',
    alpha3: 'ltu',
    en: 'Lithuania',
    phone: '370',
    de: 'Litauen',
    es: 'Lituania',
    fr: 'Lituanie',
    it: 'Lituania',
    ja: 'リトアニア',
    nl: 'Litouwen',
    pt: 'Lituânia',
    ru: 'Литва',
    sv: 'Litauen',
    zh: '立陶宛',
  },
  {
    alpha2: 'lu',
    alpha3: 'lux',
    en: 'Luxembourg',
    phone: '352',
    de: 'Luxemburg',
    es: 'Luxemburgo',
    fr: 'Luxembourg',
    it: 'Lussemburgo',
    ja: 'ルクセンブルク',
    nl: 'Luxemburg',
    pt: 'Luxemburgo',
    ru: 'Люксембург',
    sv: 'Luxemburg',
    zh: '卢森堡',
  },
  {
    alpha2: 'mo',
    alpha3: 'mac',
    en: 'Macao',
    phone: '853',
    de: 'Macau',
    es: 'Macao',
    fr: 'Macao',
    it: 'Macao',
    ja: 'マカオ',
    nl: 'Macau',
    pt: 'Macau',
    ru: 'Макао',
    sv: 'Macao',
    zh: '澳门',
  },
  {
    alpha2: 'mk',
    alpha3: 'mkd',
    en: 'North Macedonia',
    de: 'Nordmazedonien',
    es: 'Macedonia del Norte',
    fr: 'Macédoine du Nord',
    it: 'Macedonia del Nord',
    ja: '北マケドニア',
    nl: 'Noord-Macedonië',
    pt: 'Macedônia do Norte',
    ru: 'Северная Македония',
    sv: 'Nordmakedonien',
    zh: '北马其顿',
  },
  {
    alpha2: 'mg',
    alpha3: 'mdg',
    en: 'Madagascar',
    phone: '261',
    de: 'Madagaskar',
    es: 'Madagascar',
    fr: 'Madagascar',
    it: 'Madagascar',
    ja: 'マダガスカル',
    nl: 'Madagaskar',
    pt: 'Madagáscar',
    ru: 'Мадагаскар',
    sv: 'Madagaskar',
    zh: '马达加斯加',
  },
  {
    alpha2: 'mw',
    alpha3: 'mwi',
    en: 'Malawi',
    phone: '265',
    de: 'Malawi',
    es: 'Malaui',
    fr: 'Malawi',
    it: 'Malawi',
    ja: 'マラウイ',
    nl: 'Malawi',
    pt: 'Maláui',
    ru: 'Малави',
    sv: 'Malawi',
    zh: '马拉维',
  },
  {
    alpha2: 'my',
    alpha3: 'mys',
    en: 'Malaysia',
    phone: '60',
    de: 'Malaysia',
    es: 'Malasia',
    fr: 'Malaisie',
    it: 'Malaysia',
    ja: 'マレーシア',
    nl: 'Maleisië',
    pt: 'Malásia',
    ru: 'Малайзия',
    sv: 'Malaysia',
    zh: '马来西亚',
  },
  {
    alpha2: 'mv',
    alpha3: 'mdv',
    en: 'Maldives',
    phone: '960',
    de: 'Malediven',
    es: 'Maldivas',
    fr: 'Maldives',
    it: 'Maldive',
    ja: 'モルディブ',
    nl: 'Malediven',
    pt: 'Maldivas',
    ru: 'Мальдивы',
    sv: 'Maldiverna',
    zh: '马尔代夫',
  },
  {
    alpha2: 'ml',
    alpha3: 'mli',
    en: 'Mali',
    phone: '223',
    de: 'Mali',
    es: 'Malí',
    fr: 'Mali',
    it: 'Mali',
    ja: 'マリ',
    nl: 'Mali',
    pt: 'Mali',
    ru: 'Мали',
    sv: 'Mali',
    zh: '马里',
  },
  {
    alpha2: 'mt',
    alpha3: 'mlt',
    en: 'Malta',
    phone: '356',
    de: 'Malta',
    es: 'Malta',
    fr: 'Malte',
    it: 'Malta',
    ja: 'マルタ',
    nl: 'Malta',
    pt: 'Malta',
    ru: 'Мальта',
    sv: 'Malta',
    zh: '马耳他',
  },
  {
    alpha2: 'mh',
    alpha3: 'mhl',
    en: 'Marshall Islands',
    phone: '692',
    de: 'Marshallinseln',
    es: 'Islas Marshall',
    fr: 'Îles Marshall',
    it: 'Isole Marshall',
    ja: 'マーシャル諸島',
    nl: 'Marshalleilanden',
    pt: 'Ilhas Marshall',
    ru: 'Маршалловы Острова',
    sv: 'Marshallöarna',
    zh: '马绍尔群岛',
  },
  {
    alpha2: 'mq',
    alpha3: 'mtq',
    en: 'Martinique',
    phone: '596',
    de: 'Martinique',
    es: 'Martinica',
    fr: 'Martinique',
    it: 'Martinica',
    ja: 'マルティニーク',
    nl: 'Martinique',
    pt: 'Martinica',
    ru: 'Мартиника',
    sv: 'Martinique',
    zh: '马提尼克',
  },
  {
    alpha2: 'mr',
    alpha3: 'mrt',
    en: 'Mauritania',
    phone: '222',
    de: 'Mauretanien',
    es: 'Mauritania',
    fr: 'Mauritanie',
    it: 'Mauritania',
    ja: 'モーリタニア',
    nl: 'Mauritanië',
    pt: 'Mauritânia',
    ru: 'Мавритания',
    sv: 'Mauretanien',
    zh: '毛里塔尼亚',
  },
  {
    alpha2: 'mu',
    alpha3: 'mus',
    en: 'Mauritius',
    phone: '230',
    de: 'Mauritius',
    es: 'Mauricio',
    fr: 'Maurice',
    it: 'Mauritius',
    ja: 'モーリシャス',
    nl: 'Mauritius',
    pt: 'Ilhas Maurícias',
    ru: 'Маврикий',
    sv: 'Mauritius',
    zh: '毛里求斯',
  },
  {
    alpha2: 'yt',
    alpha3: 'myt',
    en: 'Mayotte',
    phone: '262',
    de: 'Mayotte',
    es: 'Mayotte',
    fr: 'Mayotte',
    it: 'Mayotte',
    ja: 'マヨット',
    nl: 'Mayotte',
    pt: 'Maiote',
    ru: 'Майотта',
    sv: 'Mayotte',
    zh: '马约特',
  },
  {
    alpha2: 'mx',
    alpha3: 'mex',
    en: 'Mexico',
    phone: '52',
    de: 'Mexiko',
    es: 'México',
    fr: 'Mexique',
    it: 'Messico',
    ja: 'メキシコ',
    nl: 'Mexico',
    pt: 'México',
    ru: 'Мексика',
    sv: 'Mexiko',
    zh: '墨西哥',
  },
  {
    alpha2: 'fm',
    alpha3: 'fsm',
    en: 'Micronesia (Federated States of)',
    phone: '691',
    de: 'Mikronesien',
    es: 'Micronesia',
    fr: 'États fédérés de Micronésie',
    it: 'Micronesia',
    ja: 'ミクロネシア連邦',
    nl: 'Micronesië',
    pt: 'Estados Federados da Micronésia',
    ru: 'Микронезия',
    sv: 'Mikronesiska federationen',
    zh: '密克罗尼西亚联邦',
  },
  {
    alpha2: 'ma',
    alpha3: 'mar',
    en: 'Morocco',
    phone: '212',
    de: 'Marokko',
    es: 'Marruecos',
    fr: 'Maroc',
    it: 'Marocco',
    ja: 'モロッコ',
    nl: 'Marokko',
    pt: 'Marrocos',
    ru: 'Марокко',
    sv: 'Marocko',
    zh: '摩洛哥',
  },
  {
    alpha2: 'md',
    alpha3: 'mda',
    en: 'Moldova, Republic of',
    phone: '373',
    de: 'Moldau',
    es: 'Moldavia',
    fr: 'Moldavie',
    it: 'Moldavia',
    ja: 'モルドバ共和国',
    nl: 'Moldavië',
    pt: 'Moldávia',
    ru: 'Молдавия',
    sv: 'Moldavien',
    zh: '摩尔多瓦',
  },
  {
    alpha2: 'mc',
    alpha3: 'mco',
    en: 'Monaco',
    phone: '377',
    de: 'Monaco',
    es: 'Mónaco',
    fr: 'Monaco',
    it: 'Monaco',
    ja: 'モナコ',
    nl: 'Monaco',
    pt: 'Mónaco',
    ru: 'Монако',
    sv: 'Monaco',
    zh: '摩纳哥',
  },
  {
    alpha2: 'mn',
    alpha3: 'mng',
    en: 'Mongolia',
    phone: '976',
    de: 'Mongolei',
    es: 'Mongolia',
    fr: 'Mongolie',
    it: 'Mongolia',
    ja: 'モンゴル',
    nl: 'Mongolië',
    pt: 'Mongólia',
    ru: 'Монголия',
    sv: 'Mongoliet',
    zh: '蒙古',
  },
  {
    alpha2: 'me',
    alpha3: 'mne',
    en: 'Montenegro',
    phone: '382',
    de: 'Montenegro',
    es: 'Montenegro',
    fr: 'Monténégro',
    it: 'Montenegro',
    ja: 'モンテネグロ',
    nl: 'Montenegro',
    pt: 'Montenegro',
    ru: 'Черногория',
    sv: 'Montenegro',
    zh: '黑山',
  },
  {
    alpha2: 'ms',
    alpha3: 'msr',
    en: 'Montserrat',
    phone: '1-664',
    de: 'Montserrat',
    es: 'Montserrat',
    fr: 'Montserrat',
    it: 'Montserrat',
    ja: 'モントセラト',
    nl: 'Montserrat',
    pt: 'Monserrate',
    ru: 'Монтсеррат',
    sv: 'Montserrat',
    zh: '蒙特塞拉特',
  },
  {
    alpha2: 'mz',
    alpha3: 'moz',
    en: 'Mozambique',
    phone: '258',
    de: 'Mosambik',
    es: 'Mozambique',
    fr: 'Mozambique',
    it: 'Mozambico',
    ja: 'モザンビーク',
    nl: 'Mozambique',
    pt: 'Moçambique',
    ru: 'Мозамбик',
    sv: 'Moçambique',
    zh: '莫桑比克',
  },
  {
    alpha2: 'mm',
    alpha3: 'mmr',
    en: 'Myanmar',
    phone: '95',
    de: 'Myanmar',
    es: 'Birmania',
    fr: 'Birmanie',
    it: 'Birmania',
    ja: 'ミャンマー',
    nl: 'Myanmar',
    pt: 'Mianmar',
    ru: 'Мьянма',
    sv: 'Myanmar',
    zh: '缅甸',
  },
  {
    alpha2: 'na',
    alpha3: 'nam',
    en: 'Namibia',
    phone: '264',
    de: 'Namibia',
    es: 'Namibia',
    fr: 'Namibie',
    it: 'Namibia',
    ja: 'ナミビア',
    nl: 'Namibië',
    pt: 'Namíbia',
    ru: 'Намибия',
    sv: 'Namibia',
    zh: '纳米比亚',
  },
  {
    alpha2: 'nr',
    alpha3: 'nru',
    en: 'Nauru',
    phone: '674',
    de: 'Nauru',
    es: 'Nauru',
    fr: 'Nauru',
    it: 'Nauru',
    ja: 'ナウル',
    nl: 'Nauru',
    pt: 'Nauru',
    ru: 'Науру',
    sv: 'Nauru',
    zh: '瑙鲁',
  },
  {
    alpha2: 'np',
    alpha3: 'npl',
    en: 'Nepal',
    phone: '977',
    de: 'Nepal',
    es: 'Nepal',
    fr: 'Népal',
    it: 'Nepal',
    ja: 'ネパール',
    nl: 'Nepal',
    pt: 'Nepal',
    ru: 'Непал',
    sv: 'Nepal',
    zh: '尼泊尔',
  },
  {
    alpha2: 'nl',
    alpha3: 'nld',
    en: 'Netherlands',
    phone: '31',
    de: 'Niederlande',
    es: 'Países Bajos',
    fr: 'Pays-Bas',
    it: 'Paesi Bassi',
    ja: 'オランダ',
    nl: 'Nederland',
    pt: 'Países Baixos',
    ru: 'Нидерланды',
    sv: 'Nederländerna',
    zh: '荷兰',
  },
  {
    alpha2: 'nc',
    alpha3: 'ncl',
    en: 'New Caledonia',
    phone: '687',
    de: 'Neukaledonien',
    es: 'Nueva Caledonia',
    fr: 'Nouvelle-Calédonie',
    it: 'Nuova Caledonia',
    ja: 'ニューカレドニア',
    nl: 'Nieuw-Caledonië',
    pt: 'Nova Caledónia',
    ru: 'Новая Каледония',
    sv: 'Nya Kaledonien',
    zh: '新喀里多尼亚',
  },
  {
    alpha2: 'nz',
    alpha3: 'nzl',
    en: 'New Zealand',
    phone: '64',
    de: 'Neuseeland',
    es: 'Nueva Zelanda',
    fr: 'Nouvelle-Zélande',
    it: 'Nuova Zelanda',
    ja: 'ニュージーランド',
    nl: 'Nieuw-Zeeland',
    pt: 'Nova Zelândia',
    ru: 'Новая Зеландия',
    sv: 'Nya Zeeland',
    zh: '新西兰',
  },
  {
    alpha2: 'ni',
    alpha3: 'nic',
    en: 'Nicaragua',
    phone: '505',
    de: 'Nicaragua',
    es: 'Nicaragua',
    fr: 'Nicaragua',
    it: 'Nicaragua',
    ja: 'ニカラグア',
    nl: 'Nicaragua',
    pt: 'Nicarágua',
    ru: 'Никарагуа',
    sv: 'Nicaragua',
    zh: '尼加拉瓜',
  },
  {
    alpha2: 'ne',
    alpha3: 'ner',
    en: 'Niger',
    phone: '227',
    de: 'Niger',
    es: 'Níger',
    fr: 'Niger',
    it: 'Niger',
    ja: 'ニジェール',
    nl: 'Niger',
    pt: 'Níger',
    ru: 'Нигер',
    sv: 'Niger',
    zh: '尼日尔',
  },
  {
    alpha2: 'ng',
    alpha3: 'nga',
    en: 'Nigeria',
    phone: '234',
    de: 'Nigeria',
    es: 'Nigeria',
    fr: 'Nigeria',
    it: 'Nigeria',
    ja: 'ナイジェリア',
    nl: 'Nigeria',
    pt: 'Nigéria',
    ru: 'Нигерия',
    sv: 'Nigeria',
    zh: '尼日利亚',
  },
  {
    alpha2: 'nu',
    alpha3: 'niu',
    en: 'Niue',
    phone: '683',
    de: 'Niue',
    es: 'Niue',
    fr: 'Niue',
    it: 'Niue',
    ja: 'ニウエ',
    nl: 'Niue',
    pt: 'Niue',
    ru: 'Ниуэ',
    sv: 'Niue',
    zh: '纽埃',
  },
  {
    alpha2: 'nf',
    alpha3: 'nfk',
    en: 'Norfolk Island',
    phone: '672',
    de: 'Norfolkinsel',
    es: 'Isla Norfolk',
    fr: 'Île Norfolk',
    it: 'Isola Norfolk',
    ja: 'ノーフォーク島',
    nl: 'Norfolk',
    pt: 'Ilha Norfolk',
    ru: 'Остров Норфолк',
    sv: 'Norfolkön',
    zh: '诺福克岛',
  },
  {
    alpha2: 'mp',
    alpha3: 'mnp',
    en: 'Northern Mariana Islands',
    phone: '1-670',
    de: 'Nördliche Marianen',
    es: 'Islas Marianas del Norte',
    fr: 'Îles Mariannes du Nord',
    it: 'Isole Marianne Settentrionali',
    ja: '北マリアナ諸島',
    nl: 'Noordelijke Marianen',
    pt: 'Marianas Setentrionais',
    ru: 'Северные Марианские Острова',
    sv: 'Nordmarianerna',
    zh: '北马里亚纳群岛',
  },
  {
    alpha2: 'no',
    alpha3: 'nor',
    en: 'Norway',
    phone: '47',
    de: 'Norwegen',
    es: 'Noruega',
    fr: 'Norvège',
    it: 'Norvegia',
    ja: 'ノルウェー',
    nl: 'Noorwegen',
    pt: 'Noruega',
    ru: 'Норвегия',
    sv: 'Norge',
    zh: '挪威',
  },
  {
    alpha2: 'om',
    alpha3: 'omn',
    en: 'Oman',
    phone: '968',
    de: 'Oman',
    es: 'Omán',
    fr: 'Oman',
    it: 'Oman',
    ja: 'オマーン',
    nl: 'Oman',
    pt: 'Omã',
    ru: 'Оман',
    sv: 'Oman',
    zh: '阿曼',
  },
  {
    alpha2: 'pk',
    alpha3: 'pak',
    en: 'Pakistan',
    phone: '92',
    de: 'Pakistan',
    es: 'Pakistán',
    fr: 'Pakistan',
    it: 'Pakistan',
    ja: 'パキスタン',
    nl: 'Pakistan',
    pt: 'Paquistão',
    ru: 'Пакистан',
    sv: 'Pakistan',
    zh: '巴基斯坦',
  },
  {
    alpha2: 'pw',
    alpha3: 'plw',
    en: 'Palau',
    phone: '680',
    de: 'Palau',
    es: 'Palaos',
    fr: 'Palaos',
    it: 'Palau',
    ja: 'パラオ',
    nl: 'Palau',
    pt: 'Palau',
    ru: 'Палау',
    sv: 'Palau',
    zh: '帕劳',
  },
  {
    alpha2: 'ps',
    alpha3: 'pse',
    en: 'Palestine, State of',
    de: 'Palästina',
    es: 'Palestina',
    fr: 'Palestine',
    it: 'Palestina',
    ja: 'パレスチナ',
    nl: 'Palestina',
    pt: 'Palestina',
    ru: 'Государство Палестина',
    sv: 'Palestina',
    zh: '巴勒斯坦',
  },
  {
    alpha2: 'pa',
    alpha3: 'pan',
    en: 'Panama',
    phone: '507',
    de: 'Panama',
    es: 'Panamá',
    fr: 'Panama',
    it: 'Panama',
    ja: 'パナマ',
    nl: 'Panama',
    pt: 'Panamá',
    ru: 'Панама',
    sv: 'Panama',
    zh: '巴拿马',
  },
  {
    alpha2: 'pg',
    alpha3: 'png',
    en: 'Papua New Guinea',
    phone: '675',
    de: 'Papua-Neuguinea',
    es: 'Papúa Nueva Guinea',
    fr: 'Papouasie-Nouvelle-Guinée',
    it: 'Papua Nuova Guinea',
    ja: 'パプアニューギニア',
    nl: 'Papoea-Nieuw-Guinea',
    pt: 'Papua-Nova Guiné',
    ru: 'Папуа — Новая Гвинея',
    sv: 'Papua Nya Guinea',
    zh: '巴布亚新几内亚',
  },
  {
    alpha2: 'py',
    alpha3: 'pry',
    en: 'Paraguay',
    phone: '595',
    de: 'Paraguay',
    es: 'Paraguay',
    fr: 'Paraguay',
    it: 'Paraguay',
    ja: 'パラグアイ',
    nl: 'Paraguay',
    pt: 'Paraguai',
    ru: 'Парагвай',
    sv: 'Paraguay',
    zh: '巴拉圭',
  },
  {
    alpha2: 'pe',
    alpha3: 'per',
    en: 'Peru',
    phone: '51',
    de: 'Peru',
    es: 'Perú',
    fr: 'Pérou',
    it: 'Perù',
    ja: 'ペルー',
    nl: 'Peru',
    pt: 'Peru',
    ru: 'Перу',
    sv: 'Peru',
    zh: '秘鲁',
  },
  {
    alpha2: 'ph',
    alpha3: 'phl',
    en: 'Philippines',
    phone: '63',
    de: 'Philippinen',
    es: 'Filipinas',
    fr: 'Philippines',
    it: 'Filippine',
    ja: 'フィリピン',
    nl: 'Filipijnen',
    pt: 'Filipinas',
    ru: 'Филиппины',
    sv: 'Filippinerna',
    zh: '菲律宾',
  },
  {
    alpha2: 'pn',
    alpha3: 'pcn',
    en: 'Pitcairn',
    phone: '870',
    de: 'Pitcairninseln',
    es: 'Islas Pitcairn',
    fr: 'Îles Pitcairn',
    it: 'Isole Pitcairn',
    ja: 'ピトケアン',
    nl: 'Pitcairneilanden',
    pt: 'Pitcairn',
    ru: 'Острова Питкэрн',
    sv: 'Pitcairnöarna',
    zh: '皮特凯恩群岛',
  },
  {
    alpha2: 'pl',
    alpha3: 'pol',
    en: 'Poland',
    phone: '48',
    de: 'Polen',
    es: 'Polonia',
    fr: 'Pologne',
    it: 'Polonia',
    ja: 'ポーランド',
    nl: 'Polen',
    pt: 'Polónia',
    ru: 'Польша',
    sv: 'Polen',
    zh: '波兰',
  },
  {
    alpha2: 'pt',
    alpha3: 'prt',
    en: 'Portugal',
    phone: '351',
    de: 'Portugal',
    es: 'Portugal',
    fr: 'Portugal',
    it: 'Portogallo',
    ja: 'ポルトガル',
    nl: 'Portugal',
    pt: 'Portugal',
    ru: 'Португалия',
    sv: 'Portugal',
    zh: '葡萄牙',
  },
  {
    alpha2: 'pr',
    alpha3: 'pri',
    en: 'Puerto Rico',
    phone: '1',
    de: 'Puerto Rico',
    es: 'Puerto Rico',
    fr: 'Porto Rico',
    it: 'Porto Rico',
    ja: 'プエルトリコ',
    nl: 'Puerto Rico',
    pt: 'Porto Rico',
    ru: 'Пуэрто-Рико',
    sv: 'Puerto Rico',
    zh: '波多黎各',
  },
  {
    alpha2: 'qa',
    alpha3: 'qat',
    en: 'Qatar',
    phone: '974',
    de: 'Katar',
    es: 'Catar',
    fr: 'Qatar',
    it: 'Qatar',
    ja: 'カタール',
    nl: 'Qatar',
    pt: 'Catar',
    ru: 'Катар',
    sv: 'Qatar',
    zh: '卡塔尔',
  },
  {
    alpha2: 're',
    alpha3: 'reu',
    en: 'Réunion',
    de: 'Réunion',
    es: 'Reunión',
    fr: 'La Réunion',
    it: 'La Riunione',
    ja: 'レユニオン',
    nl: 'Réunion',
    pt: 'Reunião',
    ru: 'Реюньон',
    sv: 'Réunion',
    zh: '留尼汪',
  },
  {
    alpha2: 'ro',
    alpha3: 'rou',
    en: 'Romania',
    phone: '40',
    de: 'Rumänien',
    es: 'Rumania',
    fr: 'Roumanie',
    it: 'Romania',
    ja: 'ルーマニア',
    nl: 'Roemenië',
    pt: 'Roménia',
    ru: 'Румыния',
    sv: 'Rumänien',
    zh: '罗马尼亚',
  },
  {
    alpha2: 'ru',
    alpha3: 'rus',
    en: 'Russian Federation',
    phone: '7',
    de: 'Russland',
    es: 'Rusia',
    fr: 'Russie',
    it: 'Russia',
    ja: 'ロシア連邦',
    nl: 'Rusland',
    pt: 'Rússia',
    ru: 'Россия',
    sv: 'Ryssland',
    zh: '俄罗斯',
  },
  {
    alpha2: 'rw',
    alpha3: 'rwa',
    en: 'Rwanda',
    phone: '250',
    de: 'Ruanda',
    es: 'Ruanda',
    fr: 'Rwanda',
    it: 'Ruanda',
    ja: 'ルワンダ',
    nl: 'Rwanda',
    pt: 'Ruanda',
    ru: 'Руанда',
    sv: 'Rwanda',
    zh: '卢旺达',
  },
  {
    alpha2: 'bl',
    alpha3: 'blm',
    en: 'Saint Barthélemy',
    de: 'Saint-Barthélemy',
    es: 'San Bartolomé',
    fr: 'Saint-Barthélemy',
    it: 'Saint-Barthélemy',
    ja: 'サン・バルテルミー',
    nl: 'Saint-Barthélemy',
    pt: 'São Bartolomeu',
    ru: 'Сен-Бартелеми',
    sv: 'Saint-Barthélemy',
    zh: '圣巴泰勒米',
  },
  {
    alpha2: 'sh',
    alpha3: 'shn',
    en: 'Saint Helena, Ascension and Tristan da Cunha',
    de: 'St. Helena, Ascension und Tristan da Cunha',
    es: 'Santa Elena, Ascensión y Tristán de Acuña',
    fr: 'Sainte-Hélène, Ascension et Tristan da Cunha',
    it: "Sant'Elena, Ascensione e Tristan da Cunha",
    ja: 'セントヘレナ・アセンションおよびトリスタンダクーニャ',
    nl: 'Sint-Helena, Ascension en Tristan da Cunha',
    pt: 'Santa Helena, Ascensão e Tristão da Cunha',
    ru: 'Остров Святой Елены',
    sv: 'Sankta Helena, Ascension och Tristan da Cunha',
    zh: '圣赫勒拿、阿森松和特里斯坦-达库尼亚',
  },
  {
    alpha2: 'kn',
    alpha3: 'kna',
    en: 'Saint Kitts and Nevis',
    phone: '1-869',
    de: 'St. Kitts und Nevis',
    es: 'San Cristóbal y Nieves',
    fr: 'Saint-Christophe-et-Niévès',
    it: 'Saint Kitts e Nevis',
    ja: 'セントクリストファー・ネイビス',
    nl: 'Saint Kitts en Nevis',
    pt: 'São Cristóvão e Neves',
    ru: 'Сент-Китс и Невис',
    sv: 'Saint Kitts och Nevis',
    zh: '圣基茨和尼维斯',
  },
  {
    alpha2: 'lc',
    alpha3: 'lca',
    en: 'Saint Lucia',
    phone: '1-758',
    de: 'St. Lucia',
    es: 'Santa Lucía',
    fr: 'Sainte-Lucie',
    it: 'Saint Lucia',
    ja: 'セントルシア',
    nl: 'Saint Lucia',
    pt: 'Santa Lúcia',
    ru: 'Сент-Люсия',
    sv: 'Saint Lucia',
    zh: '圣卢西亚',
  },
  {
    alpha2: 'mf',
    alpha3: 'maf',
    en: 'Saint Martin (French part)',
    phone: '590',
    de: 'Saint-Martin (französischer Teil)',
    es: 'San Martín (parte francesa)',
    fr: 'Saint-Martin (partie française)',
    it: 'Saint-Martin (parte francese)',
    ja: 'サン・マルタン（フランス領）',
    nl: 'Saint-Martin (Frans gedeelte)',
    pt: 'São Martinho (parte francesa)',
    ru: 'Сен-Мартен (французская часть)',
    sv: 'Saint-Martin (fransk del)',
    zh: '法属圣马丁',
  },
  {
    alpha2: 'pm',
    alpha3: 'spm',
    en: 'Saint Pierre and Miquelon',
    phone: '508',
    de: 'Saint-Pierre und Miquelon',
    es: 'San Pedro y Miquelón',
    fr: 'Saint-Pierre-et-Miquelon',
    it: 'Saint-Pierre e Miquelon',
    ja: 'サンピエール島・ミクロン島',
    nl: 'Saint-Pierre en Miquelon',
    pt: 'São Pedro e Miquelão',
    ru: 'Сен-Пьер и Микелон',
    sv: 'Saint-Pierre och Miquelon',
    zh: '圣皮埃尔和密克隆',
  },
  {
    alpha2: 'vc',
    alpha3: 'vct',
    en: 'Saint Vincent and the Grenadines',
    phone: '1-784',
    de: 'St. Vincent und die Grenadinen',
    es: 'San Vicente y las Granadinas',
    fr: 'Saint-Vincent-et-les-Grenadines',
    it: 'Saint Vincent e Grenadine',
    ja: 'セントビンセントおよびグレナディーン諸島',
    nl: 'Saint Vincent en de Grenadines',
    pt: 'São Vicente e Granadinas',
    ru: 'Сент-Винсент и Гренадины',
    sv: 'Saint Vincent och Grenadinerna',
    zh: '圣文森特和格林纳丁斯',
  },
  {
    alpha2: 'ws',
    alpha3: 'wsm',
    en: 'Samoa',
    phone: '685',
    de: 'Samoa',
    es: 'Samoa',
    fr: 'Samoa',
    it: 'Samoa',
    ja: 'サモア',
    nl: 'Samoa',
    pt: 'Samoa',
    ru: 'Самоа',
    sv: 'Samoa',
    zh: '萨摩亚',
  },
  {
    alpha2: 'sm',
    alpha3: 'smr',
    en: 'San Marino',
    phone: '378',
    de: 'San Marino',
    es: 'San Marino',
    fr: 'Saint-Marin',
    it: 'San Marino',
    ja: 'サンマリノ',
    nl: 'San Marino',
    pt: 'San Marino',
    ru: 'Сан-Марино',
    sv: 'San Marino',
    zh: '圣马力诺',
  },
  {
    alpha2: 'st',
    alpha3: 'stp',
    en: 'Sao Tome and Principe',
    phone: '239',
    de: 'São Tomé und Príncipe',
    es: 'Santo Tomé y Príncipe',
    fr: 'Sao Tomé-et-Principe',
    it: 'São Tomé e Príncipe',
    ja: 'サントメ・プリンシペ',
    nl: 'Sao Tomé en Principe',
    pt: 'São Tomé e Príncipe',
    ru: 'Сан-Томе и Принсипи',
    sv: 'São Tomé och Príncipe',
    zh: '圣多美和普林西比',
  },
  {
    alpha2: 'sa',
    alpha3: 'sau',
    en: 'Saudi Arabia',
    phone: '966',
    de: 'Saudi-Arabien',
    es: 'Arabia Saudita',
    fr: 'Arabie saoudite',
    it: 'Arabia Saudita',
    ja: 'サウジアラビア',
    nl: 'Saoedi-Arabië',
    pt: 'Arábia Saudita',
    ru: 'Саудовская Аравия',
    sv: 'Saudiarabien',
    zh: '沙特阿拉伯',
  },
  {
    alpha2: 'sn',
    alpha3: 'sen',
    en: 'Senegal',
    phone: '221',
    de: 'Senegal',
    es: 'Senegal',
    fr: 'Sénégal',
    it: 'Senegal',
    ja: 'セネガル',
    nl: 'Senegal',
    pt: 'Senegal',
    ru: 'Сенегал',
    sv: 'Senegal',
    zh: '塞内加尔',
  },
  {
    alpha2: 'rs',
    alpha3: 'srb',
    en: 'Serbia',
    phone: '381',
    de: 'Serbien',
    es: 'Serbia',
    fr: 'Serbie',
    it: 'Serbia',
    ja: 'セルビア',
    nl: 'Servië',
    pt: 'Sérvia',
    ru: 'Сербия',
    sv: 'Serbien',
    zh: '塞尔维亚',
  },
  {
    alpha2: 'sc',
    alpha3: 'syc',
    en: 'Seychelles',
    phone: '248',
    de: 'Seychellen',
    es: 'Seychelles',
    fr: 'Seychelles',
    it: 'Seychelles',
    ja: 'セーシェル',
    nl: 'Seychellen',
    pt: 'Seicheles',
    ru: 'Сейшельские Острова',
    sv: 'Seychellerna',
    zh: '塞舌尔',
  },
  {
    alpha2: 'sl',
    alpha3: 'sle',
    en: 'Sierra Leone',
    phone: '232',
    de: 'Sierra Leone',
    es: 'Sierra Leona',
    fr: 'Sierra Leone',
    it: 'Sierra Leone',
    ja: 'シエラレオネ',
    nl: 'Sierra Leone',
    pt: 'Serra Leoa',
    ru: 'Сьерра-Леоне',
    sv: 'Sierra Leone',
    zh: '塞拉利昂',
  },
  {
    alpha2: 'sg',
    alpha3: 'sgp',
    en: 'Singapore',
    phone: '65',
    de: 'Singapur',
    es: 'Singapur',
    fr: 'Singapour',
    it: 'Singapore',
    ja: 'シンガポール',
    nl: 'Singapore',
    pt: 'Singapura',
    ru: 'Сингапур',
    sv: 'Singapore',
    zh: '新加坡',
  },
  {
    alpha2: 'sx',
    alpha3: 'sxm',
    en: 'Sint Maarten (Dutch part)',
    phone: '1-721',
    de: 'Sint Maarten (Niederländischer Teil)',
    es: 'San Martín (parte neerlandesa)',
    fr: 'Saint-Martin (partie néerlandaise)',
    it: 'Sint Maarten (parte olandese)',
    ja: 'シント・マールテン（オランダ領）',
    nl: 'Sint Maarten (Nederlands gedeelte)',
    pt: 'São Martinho (parte neerlandesa)',
    ru: 'Синт-Мартен (голландская часть)',
    sv: 'Sint Maarten (nederländska delen)',
    zh: '荷属圣马丁',
  },
  {
    alpha2: 'sk',
    alpha3: 'svk',
    en: 'Slovakia',
    phone: '421',
    de: 'Slowakei',
    es: 'Eslovaquia',
    fr: 'Slovaquie',
    it: 'Slovacchia',
    ja: 'スロバキア',
    nl: 'Slowakije',
    pt: 'Eslováquia',
    ru: 'Словакия',
    sv: 'Slovakien',
    zh: '斯洛伐克',
  },
  {
    alpha2: 'si',
    alpha3: 'svn',
    en: 'Slovenia',
    phone: '386',
    de: 'Slowenien',
    es: 'Eslovenia',
    fr: 'Slovénie',
    it: 'Slovenia',
    ja: 'スロベニア',
    nl: 'Slovenië',
    pt: 'Eslovênia',
    ru: 'Словения',
    sv: 'Slovenien',
    zh: '斯洛文尼亚',
  },
  {
    alpha2: 'sb',
    alpha3: 'slb',
    en: 'Solomon Islands',
    phone: '677',
    de: 'Salomonen',
    es: 'Islas Salomón',
    fr: 'Îles Salomon',
    it: 'Isole Salomone',
    ja: 'ソロモン諸島',
    nl: 'Salomonseilanden',
    pt: 'Ilhas Salomão',
    ru: 'Соломоновы Острова',
    sv: 'Salomonöarna',
    zh: '所罗门群岛',
  },
  {
    alpha2: 'so',
    alpha3: 'som',
    en: 'Somalia',
    phone: '252',
    de: 'Somalia',
    es: 'Somalia',
    fr: 'Somalie',
    it: 'Somalia',
    ja: 'ソマリア',
    nl: 'Somalië',
    pt: 'Somália',
    ru: 'Сомали',
    sv: 'Somalia',
    zh: '索马里',
  },
  {
    alpha2: 'za',
    alpha3: 'zaf',
    en: 'South Africa',
    phone: '27',
    de: 'Südafrika',
    es: 'Sudáfrica',
    fr: 'Afrique du Sud',
    it: 'Sudafrica',
    ja: '南アフリカ',
    nl: 'Zuid-Afrika',
    pt: 'África do Sul',
    ru: 'ЮАР',
    sv: 'Sydafrika',
    zh: '南非',
  },
  {
    alpha2: 'gs',
    alpha3: 'sgs',
    en: 'South Georgia and the South Sandwich Islands',
    phone: '500',
    de: 'Südgeorgien und die Südlichen Sandwichinseln',
    es: 'Islas Georgias del Sur y Sandwich del Sur',
    fr: 'Géorgie du Sud-et-les îles Sandwich du Sud',
    it: 'Georgia del Sud e Isole Sandwich Australi',
    ja: 'サウスジョージア・サウスサンドウィッチ諸島',
    nl: 'Zuid-Georgia en de Zuidelijke Sandwicheilanden',
    pt: 'Ilhas Geórgia do Sul e Sandwich do Sul',
    ru: 'Южная Георгия и Южные Сандвичевы Острова',
    sv: 'Sydgeorgien och Sydsandwichöarna',
    zh: '南乔治亚和南桑威奇群岛',
  },
  {
    alpha2: 'ss',
    alpha3: 'ssd',
    en: 'South Sudan',
    phone: '211',
    de: 'Südsudan',
    es: 'Sudán del Sur',
    fr: 'Soudan du Sud',
    it: 'Sudan del Sud',
    ja: '南スーダン',
    nl: 'Zuid-Soedan',
    pt: 'Sudão do Sul',
    ru: 'Южный Судан',
    sv: 'Sydsudan',
    zh: '南苏丹',
  },
  {
    alpha2: 'es',
    alpha3: 'esp',
    en: 'Spain',
    phone: '34',
    de: 'Spanien',
    es: 'España',
    fr: 'Espagne',
    it: 'Spagna',
    ja: 'スペイン',
    nl: 'Spanje',
    pt: 'Espanha',
    ru: 'Испания',
    sv: 'Spanien',
    zh: '西班牙',
  },
  {
    alpha2: 'lk',
    alpha3: 'lka',
    en: 'Sri Lanka',
    phone: '94',
    de: 'Sri Lanka',
    es: 'Sri Lanka',
    fr: 'Sri Lanka',
    it: 'Sri Lanka',
    ja: 'スリランカ',
    nl: 'Sri Lanka',
    pt: 'Seri Lanca',
    ru: 'Шри-Ланка',
    sv: 'Sri Lanka',
    zh: '斯里兰卡',
  },
  {
    alpha2: 'sd',
    alpha3: 'sdn',
    en: 'Sudan',
    phone: '249',
    de: 'Sudan',
    es: 'Sudán',
    fr: 'Soudan',
    it: 'Sudan',
    ja: 'スーダン',
    nl: 'Soedan',
    pt: 'Sudão',
    ru: 'Судан',
    sv: 'Sudan',
    zh: '苏丹',
  },
  {
    alpha2: 'sr',
    alpha3: 'sur',
    en: 'Suriname',
    phone: '597',
    de: 'Suriname',
    es: 'Surinam',
    fr: 'Suriname',
    it: 'Suriname',
    ja: 'スリナム',
    nl: 'Suriname',
    pt: 'Suriname',
    ru: 'Суринам',
    sv: 'Surinam',
    zh: '苏里南',
  },
  {
    alpha2: 'sj',
    alpha3: 'sjm',
    en: 'Svalbard and Jan Mayen',
    phone: '47',
    de: 'Spitzbergen und Jan Mayen',
    es: 'Svalbard y Jan Mayen',
    fr: 'Svalbard et île Jan Mayen',
    it: 'Svalbard e Jan Mayen',
    ja: 'スヴァールバル諸島およびヤンマイエン島',
    nl: 'Spitsbergen en Jan Mayen',
    pt: 'Esvalbarde e Jan Mayen',
    ru: 'Шпицберген и Ян-Майен',
    sv: 'Svalbard och Jan Mayen',
    zh: '斯瓦尔巴和扬马延',
  },
  {
    alpha2: 'sz',
    alpha3: 'swz',
    en: 'Eswatini',
    phone: '268',
    de: 'Eswatini',
    es: 'Suazilandia',
    fr: 'Eswatini',
    it: 'eSwatini',
    ja: 'エスワティニ',
    nl: 'Swaziland',
    pt: 'Essuatíni',
    ru: 'Эсватини',
    sv: 'Swaziland',
    zh: '斯威士兰',
  },
  {
    alpha2: 'se',
    alpha3: 'swe',
    en: 'Sweden',
    phone: '46',
    de: 'Schweden',
    es: 'Suecia',
    fr: 'Suède',
    it: 'Svezia',
    ja: 'スウェーデン',
    nl: 'Zweden',
    pt: 'Suécia',
    ru: 'Швеция',
    sv: 'Sverige',
    zh: '瑞典',
  },
  {
    alpha2: 'ch',
    alpha3: 'che',
    en: 'Switzerland',
    phone: '41',
    de: 'Schweiz',
    es: 'Suiza',
    fr: 'Suisse',
    it: 'Svizzera',
    ja: 'スイス',
    nl: 'Zwitserland',
    pt: 'Suíça',
    ru: 'Швейцария',
    sv: 'Schweiz',
    zh: '瑞士',
  },
  {
    alpha2: 'sy',
    alpha3: 'syr',
    en: 'Syrian Arab Republic',
    phone: '963',
    de: 'Syrien',
    es: 'Siria',
    fr: 'Syrie',
    it: 'Siria',
    ja: 'シリア・アラブ共和国',
    nl: 'Syrië',
    pt: 'Síria',
    ru: 'Сирия',
    sv: 'Syrien',
    zh: '叙利亚',
  },
  {
    alpha2: 'tw',
    alpha3: 'twn',
    en: 'Taiwan, Province of China',
    phone: '886',
    de: 'China, Republik',
    es: 'Taiwán (República de China)',
    fr: 'Taïwan / (République de Chine (Taïwan))',
    it: 'Taiwan',
    ja: '台湾（中華民国）',
    nl: 'Taiwan',
    pt: 'Taiwan',
    ru: 'Китайская Республика',
    sv: 'Taiwan',
    zh: '中国台湾省',
  },
  {
    alpha2: 'tj',
    alpha3: 'tjk',
    en: 'Tajikistan',
    phone: '992',
    de: 'Tadschikistan',
    es: 'Tayikistán',
    fr: 'Tadjikistan',
    it: 'Tagikistan',
    ja: 'タジキスタン',
    nl: 'Tadzjikistan',
    pt: 'Tajiquistão',
    ru: 'Таджикистан',
    sv: 'Tadzjikistan',
    zh: '塔吉克斯坦',
  },
  {
    alpha2: 'tz',
    alpha3: 'tza',
    en: 'Tanzania, United Republic of',
    de: 'Tansania',
    es: 'Tanzania',
    fr: 'Tanzanie',
    it: 'Tanzania',
    ja: 'タンザニア',
    nl: 'Tanzania',
    pt: 'Tanzânia',
    ru: 'Танзания',
    sv: 'Tanzania',
    zh: '坦桑尼亚',
  },
  {
    alpha2: 'th',
    alpha3: 'tha',
    en: 'Thailand',
    phone: '66',
    de: 'Thailand',
    es: 'Tailandia',
    fr: 'Thaïlande',
    it: 'Thailandia',
    ja: 'タイ',
    nl: 'Thailand',
    pt: 'Tailândia',
    ru: 'Таиланд',
    sv: 'Thailand',
    zh: '泰国',
  },
  {
    alpha2: 'tl',
    alpha3: 'tls',
    en: 'Timor-Leste',
    phone: '670',
    de: 'Osttimor',
    es: 'Timor Oriental',
    fr: 'Timor oriental',
    it: 'Timor Est',
    ja: '東ティモール',
    nl: 'Oost-Timor',
    pt: 'Timor-Leste',
    ru: 'Восточный Тимор',
    sv: 'Östtimor',
    zh: '东帝汶',
  },
  {
    alpha2: 'tg',
    alpha3: 'tgo',
    en: 'Togo',
    phone: '228',
    de: 'Togo',
    es: 'Togo',
    fr: 'Togo',
    it: 'Togo',
    ja: 'トーゴ',
    nl: 'Togo',
    pt: 'Togo',
    ru: 'Того',
    sv: 'Togo',
    zh: '多哥',
  },
  {
    alpha2: 'tk',
    alpha3: 'tkl',
    en: 'Tokelau',
    phone: '690',
    de: 'Tokelau',
    es: 'Tokelau',
    fr: 'Tokelau',
    it: 'Tokelau',
    ja: 'トケラウ',
    nl: 'Tokelau',
    pt: 'Toquelau',
    ru: 'Токелау',
    sv: 'Tokelau',
    zh: '托克劳',
  },
  {
    alpha2: 'to',
    alpha3: 'ton',
    en: 'Tonga',
    phone: '676',
    de: 'Tonga',
    es: 'Tonga',
    fr: 'Tonga',
    it: 'Tonga',
    ja: 'トンガ',
    nl: 'Tonga',
    pt: 'Tonga',
    ru: 'Тонга',
    sv: 'Tonga',
    zh: '汤加',
  },
  {
    alpha2: 'tt',
    alpha3: 'tto',
    en: 'Trinidad and Tobago',
    phone: '1-868',
    de: 'Trinidad und Tobago',
    es: 'Trinidad y Tobago',
    fr: 'Trinité-et-Tobago',
    it: 'Trinidad e Tobago',
    ja: 'トリニダード・トバゴ',
    nl: 'Trinidad en Tobago',
    pt: 'Trinidad e Tobago',
    ru: 'Тринидад и Тобаго',
    sv: 'Trinidad och Tobago',
    zh: '特立尼达和多巴哥',
  },
  {
    alpha2: 'tn',
    alpha3: 'tun',
    en: 'Tunisia',
    phone: '216',
    de: 'Tunesien',
    es: 'Túnez',
    fr: 'Tunisie',
    it: 'Tunisia',
    ja: 'チュニジア',
    nl: 'Tunesië',
    pt: 'Tunísia',
    ru: 'Тунис',
    sv: 'Tunisien',
    zh: '突尼斯',
  },
  {
    alpha2: 'tr',
    alpha3: 'tur',
    en: 'Türkiye',
    phone: '90',
    de: 'Türkei',
    es: 'Turquía',
    fr: 'Turquie',
    it: 'Turchia',
    ja: 'トルコ',
    nl: 'Turkije',
    pt: 'Turquia',
    ru: 'Турция',
    sv: 'Turkiet',
    zh: '土耳其',
  },
  {
    alpha2: 'tm',
    alpha3: 'tkm',
    en: 'Turkmenistan',
    phone: '993',
    de: 'Turkmenistan',
    es: 'Turkmenistán',
    fr: 'Turkménistan',
    it: 'Turkmenistan',
    ja: 'トルクメニスタン',
    nl: 'Turkmenistan',
    pt: 'Turcomenistão',
    ru: 'Туркменистан',
    sv: 'Turkmenistan',
    zh: '土库曼斯坦',
  },
  {
    alpha2: 'tc',
    alpha3: 'tca',
    en: 'Turks and Caicos Islands',
    phone: '1-649',
    de: 'Turks- und Caicosinseln',
    es: 'Islas Turcas y Caicos',
    fr: 'Îles Turques-et-Caïques',
    it: 'Turks e Caicos',
    ja: 'タークス・カイコス諸島',
    nl: 'Turks- en Caicoseilanden',
    pt: 'Turcas e Caicos',
    ru: 'Теркс и Кайкос',
    sv: 'Turks- och Caicosöarna',
    zh: '特克斯和凯科斯群岛',
  },
  {
    alpha2: 'tv',
    alpha3: 'tuv',
    en: 'Tuvalu',
    phone: '688',
    de: 'Tuvalu',
    es: 'Tuvalu',
    fr: 'Tuvalu',
    it: 'Tuvalu',
    ja: 'ツバル',
    nl: 'Tuvalu',
    pt: 'Tuvalu',
    ru: 'Тувалу',
    sv: 'Tuvalu',
    zh: '图瓦卢',
  },
  {
    alpha2: 'ug',
    alpha3: 'uga',
    en: 'Uganda',
    phone: '256',
    de: 'Uganda',
    es: 'Uganda',
    fr: 'Ouganda',
    it: 'Uganda',
    ja: 'ウガンダ',
    nl: 'Oeganda',
    pt: 'Uganda',
    ru: 'Уганда',
    sv: 'Uganda',
    zh: '乌干达',
  },
  {
    alpha2: 'ua',
    alpha3: 'ukr',
    en: 'Ukraine',
    phone: '380',
    de: 'Ukraine',
    es: 'Ucrania',
    fr: 'Ukraine',
    it: 'Ucraina',
    ja: 'ウクライナ',
    nl: 'Oekraïne',
    pt: 'Ucrânia',
    ru: 'Украина',
    sv: 'Ukraina',
    zh: '乌克兰',
  },
  {
    alpha2: 'ae',
    alpha3: 'are',
    en: 'United Arab Emirates',
    phone: '971',
    de: 'Vereinigte Arabische Emirate',
    es: 'Emiratos Árabes Unidos',
    fr: 'Émirats arabes unis',
    it: 'Emirati Arabi Uniti',
    ja: 'アラブ首長国連邦',
    nl: 'Verenigde Arabische Emiraten',
    pt: 'Emirados Árabes Unidos',
    ru: 'ОАЭ',
    sv: 'Förenade arabemiraten',
    zh: '阿联酋',
  },
  {
    alpha2: 'gb',
    alpha3: 'gbr',
    en: 'United Kingdom of Great Britain and Northern Ireland',
    phone: '44',
    de: 'Vereinigtes Königreich',
    es: 'Reino Unido',
    fr: 'Royaume-Uni',
    it: 'Regno Unito',
    ja: 'イギリス',
    nl: 'Verenigd Koninkrijk',
    pt: 'Reino Unido',
    ru: 'Великобритания',
    sv: 'Storbritannien',
    zh: '英国',
  },
  {
    alpha2: 'us',
    alpha3: 'usa',
    en: 'United States of America',
    phone: '1',
    de: 'Vereinigte Staaten',
    es: 'Estados Unidos',
    fr: 'États-Unis',
    it: 'Stati Uniti',
    ja: 'アメリカ合衆国',
    nl: 'Verenigde Staten',
    pt: 'Estados Unidos',
    ru: 'США',
    sv: 'USA',
    zh: '美国',
  },
  {
    alpha2: 'um',
    alpha3: 'umi',
    en: 'United States Minor Outlying Islands',
    de: 'United States Minor Outlying Islands',
    es: 'Islas Ultramarinas Menores de los Estados Unidos',
    fr: 'Îles mineures éloignées des États-Unis',
    it: 'Isole minori esterne degli Stati Uniti',
    ja: '合衆国領有小離島',
    nl: 'Kleine afgelegen eilanden van de Verenigde Staten',
    pt: 'Ilhas Menores Distantes dos Estados Unidos',
    ru: 'Внешние малые острова США',
    sv: 'USA:s yttre öar',
    zh: '美国本土外小岛屿',
  },
  {
    alpha2: 'uy',
    alpha3: 'ury',
    en: 'Uruguay',
    phone: '598',
    de: 'Uruguay',
    es: 'Uruguay',
    fr: 'Uruguay',
    it: 'Uruguay',
    ja: 'ウルグアイ',
    nl: 'Uruguay',
    pt: 'Uruguai',
    ru: 'Уругвай',
    sv: 'Uruguay',
    zh: '乌拉圭',
  },
  {
    alpha2: 'uz',
    alpha3: 'uzb',
    en: 'Uzbekistan',
    phone: '998',
    de: 'Usbekistan',
    es: 'Uzbekistán',
    fr: 'Ouzbékistan',
    it: 'Uzbekistan',
    ja: 'ウズベキスタン',
    nl: 'Oezbekistan',
    pt: 'Uzbequistão',
    ru: 'Узбекистан',
    sv: 'Uzbekistan',
    zh: '乌兹别克斯坦',
  },
  {
    alpha2: 'vu',
    alpha3: 'vut',
    en: 'Vanuatu',
    phone: '678',
    de: 'Vanuatu',
    es: 'Vanuatu',
    fr: 'Vanuatu',
    it: 'Vanuatu',
    ja: 'バヌアツ',
    nl: 'Vanuatu',
    pt: 'Vanuatu',
    ru: 'Вануату',
    sv: 'Vanuatu',
    zh: '瓦努阿图',
  },
  {
    alpha2: 've',
    alpha3: 'ven',
    en: 'Venezuela (Bolivarian Republic of)',
    de: 'Venezuela',
    es: 'Venezuela',
    fr: 'Venezuela',
    it: 'Venezuela',
    ja: 'ベネズエラ・ボリバル共和国',
    nl: 'Venezuela',
    pt: 'Venezuela',
    ru: 'Венесуэла',
    sv: 'Venezuela',
    zh: '委内瑞拉',
  },
  {
    alpha2: 'vn',
    alpha3: 'vnm',
    en: 'Viet Nam',
    de: 'Vietnam',
    es: 'Vietnam',
    fr: 'Viêt Nam',
    it: 'Vietnam',
    ja: 'ベトナム',
    nl: 'Vietnam',
    pt: 'Vietname',
    ru: 'Вьетнам',
    sv: 'Vietnam',
    zh: '越南',
  },
  {
    alpha2: 'vg',
    alpha3: 'vgb',
    en: 'Virgin Islands (British)',
    de: 'Britische Jungferninseln',
    es: 'Islas Vírgenes Británicas',
    fr: 'Îles Vierges britanniques',
    it: 'Isole Vergini Britanniche',
    ja: 'イギリス領ヴァージン諸島',
    nl: 'Britse Maagdeneilanden',
    pt: 'Ilhas Virgens Britânicas',
    ru: 'Виргинские Острова (Великобритания)',
    sv: 'Brittiska Jungfruöarna',
    zh: '英属维尔京群岛',
  },
  {
    alpha2: 'vi',
    alpha3: 'vir',
    en: 'Virgin Islands (U.S.)',
    de: 'Amerikanische Jungferninseln',
    es: 'Islas Vírgenes de los Estados Unidos',
    fr: 'Îles Vierges des États-Unis',
    it: 'Isole Vergini Americane',
    ja: 'アメリカ領ヴァージン諸島',
    nl: 'Amerikaanse Maagdeneilanden',
    pt: 'Ilhas Virgens Americanas',
    ru: 'Виргинские Острова (США)',
    sv: 'Amerikanska Jungfruöarna',
    zh: '美属维尔京群岛',
  },
  {
    alpha2: 'wf',
    alpha3: 'wlf',
    en: 'Wallis and Futuna',
    phone: '681',
    de: 'Wallis und Futuna',
    es: 'Wallis y Futuna',
    fr: 'Wallis-et-Futuna',
    it: 'Wallis e Futuna',
    ja: 'ウォリス・フツナ',
    nl: 'Wallis en Futuna',
    pt: 'Wallis e Futuna',
    ru: 'Уоллис и Футуна',
    sv: 'Wallis- och Futunaöarna',
    zh: '瓦利斯和富图纳',
  },
  {
    alpha2: 'eh',
    alpha3: 'esh',
    en: 'Western Sahara',
    phone: '212',
    de: 'Westsahara',
    es: 'República Árabe Saharaui Democrática',
    fr: 'République arabe sahraouie démocratique',
    it: 'Sahara Occidentale',
    ja: '西サハラ',
    nl: 'Westelijke Sahara/SADR',
    pt: 'Saara Ocidental',
    ru: 'САДР',
    sv: 'Västsahara',
    zh: '西撒哈拉',
  },
  {
    alpha2: 'ye',
    alpha3: 'yem',
    en: 'Yemen',
    phone: '967',
    de: 'Jemen',
    es: 'Yemen',
    fr: 'Yémen',
    it: 'Yemen',
    ja: 'イエメン',
    nl: 'Jemen',
    pt: 'Iêmen',
    ru: 'Йемен',
    sv: 'Jemen',
    zh: '也门',
  },
  {
    alpha2: 'zm',
    alpha3: 'zmb',
    en: 'Zambia',
    phone: '260',
    de: 'Sambia',
    es: 'Zambia',
    fr: 'Zambie',
    it: 'Zambia',
    ja: 'ザンビア',
    nl: 'Zambia',
    pt: 'Zâmbia',
    ru: 'Замбия',
    sv: 'Zambia',
    zh: '赞比亚',
  },
  {
    alpha2: 'zw',
    alpha3: 'zwe',
    en: 'Zimbabwe',
    phone: '263',
    de: 'Simbabwe',
    es: 'Zimbabue',
    fr: 'Zimbabwe',
    it: 'Zimbabwe',
    ja: 'ジンバブエ',
    nl: 'Zimbabwe',
    pt: 'Zimbábue',
    ru: 'Зимбабве',
    sv: 'Zimbabwe',
    zh: '津巴布韦',
  },
];
export default countries;
